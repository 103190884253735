import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEmployeeCreate } from 'api/typing/employeeTypes'


const initialState: IState<IEmployeeState> = {
    data: {} as IEmployeeState,
    isLoading: false,
    error: null,
}

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },

        addEmployee: (state, action: PayloadAction<IEmployeeCreate & { uuid: string }>) => {
            const { uuid, lastName, secondName, firstName, divisionUuid } = action.payload
            state.data.employeesCurrent.some(division => {
                if (division.uuid === divisionUuid) {
                    if (!division.employees)
                        // eslint-disable-next-line no-param-reassign
                        division.employees = []

                    division.employees.push({
                        uuid,
                        needAction: true,
                        lastName,
                        firstName,
                        photo: '',
                        secondName,
                        currentPosition: null,
                        pointPosition: null,
                    })
                }
            })
            state.error = null
            state.isLoading = false
        },

        employeeGet: (state, action: PayloadAction<IEmployee>) => {
            state.data.employee = action.payload
            state.isLoading = false
            state.error = null
        },

        employeeUpdate: (state, action: PayloadAction<Partial<IEmployee>>) => {
            state.data.employee = { ...state.data.employee, ...action.payload }
            state.isLoading = false
            state.error = null
        },
        employeeSalary: (state, action: PayloadAction<IEmployeeSalary>) => {
            state.data.salary = action.payload
            state.isLoading = false
            state.error = null
        },

        getEmployeesCurrent: (state, action: PayloadAction<IEmployeesDepartment[]>) => {
            state.data.employeesCurrent = action.payload
            state.isLoading = false
            state.error = null
        },

        getEmployeesDivisions: (state, action: PayloadAction<IEntity[]>) => {
            state.data.divisions = action.payload
            state.isLoading = false
            state.error = null
        },

        updateEmployee: (state, action: PayloadAction<Partial<IEmployee>>) => {
            state.data.employee = {
                ...state.data.employee,
                ...action.payload,
            }
            state.isLoading = false
            state.error = null
        },

        clearState: (state) => {
            state.data = {
                ...state.data,
                employee: {} as IEmployee,
                salary: {} as IEmployeeSalary,
            }
            state.isLoading = false
            state.error = null
        },
    },
})

export const employeeReducer = employeeSlice.reducer
