import { Autocomplete, Box, Chip, InputLabel, Stack, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { IPdpCreateRequest } from 'api/typing/employeeTypes'
import { AddButton } from 'components/common/AddButton/AddButton'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import ListSubheaderWithTooltip from 'components/ListSubheaderWithTooltip/ListSubheaderWithTooltip'
import {
    HINT_CHOOSE,
    HINT_EMPLOYEE_CREATE_PDP,
    HINT_ERROR_REQUIRED,
    HINT_SUCCESS_PDP_CREATED,
} from 'helpers/enums/hints'
import { PATH_DEVELOPMENT } from 'helpers/enums/routePath'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_CREATE,
    TITLE_BUTTON_CREATE_FOR_CURRENT,
    TITLE_DIALOG_PDP_CREATE,
    TITLE_FIELD_GRADE,
    TITLE_NAMESPACE_SPECIALIZATION,
} from 'helpers/enums/titles'
import { useMobile } from 'helpers/hooks/useMobile'
import React, { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { developmentPdpActions, formActions, pdpActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { useNotify } from 'store/hooks/useNotify'
import { biggerBtnForMobile } from 'styles/styles'


type DialogCreatePdpProps = {
    handleClose: () => void;
    open: boolean;
    employeeCreator?: boolean;
}

type PdpForm = Omit<IPdpCreateRequest, 'specializationUuid'> & {
    specialization: IFormSpecialization | null;
}

export const DialogCreatePdp: FC<DialogCreatePdpProps> = ({ open, handleClose, employeeCreator }) => {
    const mobile =  useMobile()
    const navigate = useNavigate()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    // const { specializationList, gradeList } = useAppSelector(state => state.pdpReducer.data)
    const { formSpecializations, formGrades } = useAppSelector(state => state.formReducer.data)
    const { employee } = useAppSelector(state => state.employeeReducer.data)
    const { grade, specialization, uuid } = useAppSelector(state => state.userReducer.data)

    const { handleSubmit, setValue, getValues, watch, control, formState: { errors } } = useForm<PdpForm>({
        defaultValues: {
            specialization: null,
            gradeUuid: '',
            employeeUuid: employee?.uuid ?? uuid,
        },
    })

    const currentSpecialization = employee?.specialization ?? specialization
    const currentGrade = employee?.grade ?? grade

    const {
        isFetching: fetchSpecializationList,
        error: specializationError,
    } = useLayoutFetch(formActions.getSpecializations())

    const { isFetching: fetchGradeList, error: gradeError } = useLayoutFetch(
        formActions.getGrades(currentSpecialization?.uuid),
        {
            deps: [currentSpecialization?.uuid],
        },
    )
    const watchSpecialization = watch('specialization')

    useEffect(() => {
        const specializationUuid = getValues('specialization')?.uuid
        if (!specializationUuid) return
        dispatch(formActions.getGrades(specializationUuid))
    }, [watchSpecialization])

    useEffect(() => {
        if (!formGrades?.some(el => el.uuid === getValues('gradeUuid')))
            setValue('gradeUuid', '')
    }, [formGrades])

    const onSubmit = async (data: PdpForm) => {
        if (!data.specialization?.uuid) return
        const payload = {
            ...data,
            specializationUuid: data.specialization.uuid,
        }
        let uuid = ''
        try {
            if (employeeCreator) {
                uuid = await dispatch(developmentPdpActions.createPdp(payload))
            } else {
                await dispatch(pdpActions.createPdp(payload))
            }
            notify(HINT_SUCCESS_PDP_CREATED, 'success')
            if (uuid && employeeCreator)
                navigate(`${PATH_DEVELOPMENT}/pdp/${uuid}`)
            handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const createForCurrent = () => {
        setValue('specialization', formSpecializations.find(el => el.uuid === currentSpecialization?.uuid) || null)
        setValue('gradeUuid', currentGrade?.uuid)
    }

    if (fetchSpecializationList || fetchGradeList) return null
    if (specializationError || gradeError) return <ErrorPage error={specializationError || gradeError}/>

    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <DialogTitle onClose={handleClose}>
                    {TITLE_DIALOG_PDP_CREATE}
                </DialogTitle>
                {specializationError || gradeError
                    ? <Box overflow="auto" py={2}>
                        <ErrorPage error={specializationError || gradeError}/>
                    </Box>
                    : <DialogContent>
                        <Stack spacing={2.5}>
                            <Box>
                                <Typography variant="body2">
                                    {HINT_EMPLOYEE_CREATE_PDP}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body1" mb={1}>
                                    Текущие специализация и грейд сотрудника
                                </Typography>
                                <Stack
                                    direction={mobile ? 'column' : 'row'}
                                    spacing={1}
                                    alignItems={mobile ? 'flex-start' : 'center'}
                                >
                                    <Chip
                                        label={`${currentSpecialization?.title} - ${currentGrade?.title}`}
                                    />
                                    <AddButton
                                        sx={{
                                            alignSelf: 'flex-start !important',
                                            paddingLeft: '0',
                                        }}
                                        onClick={createForCurrent}
                                    >
                                        {TITLE_BUTTON_CREATE_FOR_CURRENT}
                                    </AddButton>
                                </Stack>
                            </Box>
                            <Box>
                                <InputLabel htmlFor="specialization" sx={{ mb: 0.5 }}>
                                    {TITLE_NAMESPACE_SPECIALIZATION} *
                                </InputLabel>
                                <Controller
                                    name="specialization"
                                    control={control}
                                    rules={{
                                        required: HINT_ERROR_REQUIRED,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            {...field}
                                            id="specialization"
                                            options={formSpecializations}
                                            getOptionLabel={(option) => option.title}
                                            filterSelectedOptions
                                            isOptionEqualToValue={(o, v) => o.uuid === v.uuid}
                                            groupBy={option => option.parentDivisionTitle}
                                            onChange={(e, d) => field.onChange(d)}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        overflow: 'hidden',
                                                    },
                                                },
                                            }}
                                            renderGroup={({ group, key, children }) => {
                                                const currentDivision =
                                                    formSpecializations?.find(el =>
                                                        el.parentDivisionTitle === group)
                                                return (
                                                    <ListSubheaderWithTooltip key={key} tooltipText={currentDivision?.fullPath || ''} title={group}>
                                                        {children}
                                                    </ListSubheaderWithTooltip>
                                                )
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} value={option.title}>
                                                    <Typography
                                                        variant="body2"
                                                        ml={7}
                                                    >
                                                        {option.title}
                                                    </Typography>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    title={TITLE_NAMESPACE_SPECIALIZATION}
                                                    placeholder={HINT_CHOOSE}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Box>
                            <Box>
                                <Controller
                                    name="gradeUuid"
                                    control={control}
                                    rules={{
                                        required: HINT_ERROR_REQUIRED,
                                    }}
                                    render={({ field }) => (
                                        <SelectForm
                                            {...field}
                                            id="gradeUuid"
                                            title={TITLE_FIELD_GRADE}
                                            required
                                            placeholder={HINT_CHOOSE}
                                            values={formGrades || []}
                                            error={!!errors.gradeUuid}
                                            helperText={errors?.gradeUuid ? errors.gradeUuid.message : null}
                                        />
                                    )}
                                />
                            </Box>
                        </Stack>
                    </DialogContent>}
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_CANCEL}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={!mobile ? {} : biggerBtnForMobile}
                    >
                        {TITLE_BUTTON_CREATE}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

