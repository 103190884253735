import { CheckRounded } from '@mui/icons-material'
import { Avatar, Badge, Box, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
// import { PaymentsRounded } from '@mui/icons-material'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { PATH_EMPLOYEES_EDIT } from 'helpers/enums/routePath'
import { TITLE_NAMESPACE_GRADE, TITLE_NAMESPACE_SPECIALIZATION } from 'helpers/enums/titles'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
// import { employeeActions } from 'store/actions'
// import { useAppSelector } from 'store/hooks/redux'
// import { MoneyChip } from 'components/badges/MoneyChip/MoneyChip'
// import { useFetch } from 'store/hooks/useFetch'

type EmployeeHeaderProps = {
    employee: IEmployee;
    parentPath: string;
    isProfilePage?: boolean;
}

export const EmployeeHeader: FC<EmployeeHeaderProps> = ({ employee, parentPath, isProfilePage }) => {
    const navigate = useNavigate()
    // const { salary } = useAppSelector(state => state.employeeReducer.data)
    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const specializationTitle = employee?.specialization?.title ?? TITLE_NAMESPACE_SPECIALIZATION
    // const specializationUuid = employee?.specialization?.uuid
    // const gradeUuid = employee?.grade?.uuid
    const gradeTitle = employee?.grade?.title ?? TITLE_NAMESPACE_GRADE
    // const salarySum = useMemo(() =>
    //     salary?.salary + (salary?.allowances?.reduce((acc, el) => acc + el.sum, 0) || 0),
    // [salary],
    // )
    // let isFetching = false

    // void useFetch(employeeActions.employeeSalaryGet(
    //     employee.uuid, { specializationUuid, gradeUuid }),
    // [employee.uuid, specializationUuid, gradeUuid],
    // )

    // useEffect(() => {
    //     const fetchData = async () => {
    //         isFetching = true
    //         if (!employee.uuid) return
    //         // TODO: temporary, need delete when was created chains
    //         await dispatch(employeeActions.employeeSalaryGet(employee.uuid, { specializationUuid, gradeUuid }))
    //         isFetching = false
    //     }
    //     if (!isFetching) {
    //         fetchData().catch(e => e)
    //     }
    // }, [])

    // const open = Boolean(anchorEl)
    // const id = open ? 'salary-popover' : undefined

    // const openSalary = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget)
    // }

    // const closeSalary = () => {
    //     setAnchorEl(null)
    // }

    const onEdit = () => {
        employee?.uuid && navigate(`${parentPath}/${PATH_EMPLOYEES_EDIT}`)
    }

    const employeeIsNotAuthorized = !!employee.invite && !employee.invite.isAuthorized
    const badgeTooltip = employeeIsNotAuthorized ? 'Пользователь не авторизован' : ''

    const getAlertTooltip = (): { text: string, color: 'warning' | 'error', } | null  => {
        switch (true) {
            case !!badgeTooltip && !employee.alerts?.length:
                return {
                    text: badgeTooltip,
                    color: 'warning',
                }
            case !!employee.alerts?.length: {
                return {
                    text: employee.alerts?.[0].message ?? '',
                    color: employee.alerts?.[0]?.code === 'account_authorized' ? 'warning' : 'error',
                }
            }
            case !employee.active:
                return {
                    text: '',
                    color: 'error',
                }
            default: {
                return null
            }
        }
    }

    const alertTooltip = getAlertTooltip()

    return (
        <Box display="flex" mb={4} width="100%">
            <Tooltip title={alertTooltip?.text}>
                <Badge
                    badgeContent="!"
                    color={alertTooltip?.color ?? 'error'}
                    overlap="circular"
                    invisible={employee.active && !employeeIsNotAuthorized && !alertTooltip}
                    sx={{ mr: 5 }}
                >
                    <Avatar
                        src={employee?.photo}
                        sx={{
                            width: 140,
                            height: 140,
                        }}
                    />
                </Badge>
            </Tooltip>
            <Box display="flex" flex="1" justifyContent="space-between">
                <Box>
                    <Box mb={2}>
                        <Typography variant="h1">
                            {employee?.lastName} {employee?.firstName} {employee?.secondName}
                            {employee?.active && !isProfilePage ? <CheckRounded
                                sx={{
                                    color: ({ palette }) => palette.success.light,
                                    ml: 1,
                                }}
                            /> : null}
                        </Typography>

                    </Box>
                    <Box display="flex" flexWrap="wrap">
                        <Stack direction="row" spacing={1}>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: (theme) => employee?.specialization?.title ? '' : theme.palette.secondary.main,
                                }}
                            >
                                {specializationTitle}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: (theme) => employee?.specialization?.title
                                    || employee?.grade?.title
                                        ? ''
                                        : theme.palette.secondary.main,
                                }}
                            >
                                -
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: (theme) => employee?.specialization?.title ? '' : theme.palette.secondary.main,
                                }}
                            >
                                {gradeTitle}
                            </Typography>
                        </Stack>
                        <Divider sx={{ mx: 2 }} orientation="vertical" flexItem/>
                        <Typography variant="body1">
                            {employee?.division?.title || TITLE_NAMESPACE_GRADE}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    {/*{salary && <IconButton onClick={openSalary}>*/}
                    {/*    <PaymentsRounded />*/}
                    {/*</IconButton>}*/}
                    <Authenticated
                        service={isProfilePage ? 'profile' : 'employee'}
                        accessLevel="w"
                        divisions={[employee?.division?.uuid]}
                    >
                        <SecondaryActions onEdit={onEdit}/>
                    </Authenticated>
                    {/*<Popover*/}
                    {/*    id={id}*/}
                    {/*    open={open}*/}
                    {/*    anchorEl={anchorEl}*/}
                    {/*    onClose={closeSalary}*/}
                    {/*    anchorOrigin={{*/}
                    {/*        vertical: 'center',*/}
                    {/*        horizontal: 'left',*/}
                    {/*    }}*/}
                    {/*    transformOrigin={{*/}
                    {/*        vertical: 'center',*/}
                    {/*        horizontal: 'right',*/}
                    {/*    }}*/}
                    {/*    PaperProps={{*/}
                    {/*        sx: {*/}
                    {/*            maxWidth: 380,*/}
                    {/*            width: '100%',*/}
                    {/*            borderRadius: 4,*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Box p={2.5}>*/}
                    {/*        <Box>*/}
                    {/*            <Typography variant="body1" sx={{ mb: 1 }}>*/}
                    {/*                {TITLE_NAMESPACE_SALARY}*/}
                    {/*            </Typography>*/}
                    {/*            /!*<Typography variant="body2">*!/*/}
                    {/*            /!*    {salary?.salary}*!/*/}
                    {/*            /!*</Typography>*!/*/}
                    {/*            <MoneyChip sum={salary?.salary} hidePlus />*/}
                    {/*        </Box>*/}
                    {/*        /!*{salary?.allowances?.map(allowance => (*!/*/}
                    {/*        /!*    <Box key={allowance.title} mb={2}>*!/*/}
                    {/*        /!*        <Typography variant="body1" sx={{ mb: 1 }}>*!/*/}
                    {/*        /!*            {allowance.title}*!/*/}
                    {/*        /!*        </Typography>*!/*/}
                    {/*        /!*        <Typography variant="body2">*!/*/}
                    {/*        /!*            + {allowance.sum}*!/*/}
                    {/*        /!*        </Typography>*!/*/}
                    {/*        /!*    </Box>*!/*/}
                    {/*        /!*))}*!/*/}
                    {/*        /!*<Divider sx={{ mb: 2 }}/>*!/*/}
                    {/*        /!*<Box>*!/*/}
                    {/*        /!*    <Typography variant="body1" sx={{ mb: 1 }}>*!/*/}
                    {/*        /!*        Суммарный доход (руб.)*!/*/}
                    {/*        /!*    </Typography>*!/*/}
                    {/*        /!*    <Typography variant="body2">*!/*/}
                    {/*        /!*        {salarySum}*!/*/}
                    {/*        /!*    </Typography>*!/*/}
                    {/*        /!*</Box>*!/*/}
                    {/*    </Box>*/}
                    {/*</Popover>*/}
                </Box>
            </Box>
        </Box>
    )
}

