import { SendRounded } from '@mui/icons-material'
import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'
import {
    PointPositionSpecializationGrade,
} from 'components/badges/PointPositionSpecializationGrade/PointPositionSpecializationGrade'
import { DialogConfirm } from 'components/dialogs/DialogConfirm/DialogConfirm'
import { DialogConfirmDelete } from 'components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import {
    HINT_CHANGES_WILL_LOST,
    HINT_PDP_SEND_TO_APPROVAL,
    HINT_SUCCESS_PDP_DELETED,
    HINT_SUCCESS_PDP_SENT_TO_APPROVAL,
} from 'helpers/enums/hints'
import { PATH_DEVELOPMENT } from 'helpers/enums/routePath'
import {
    TITLE_BUTTON_CANCEL,
    TITLE_BUTTON_SEND_TO_APPROVAL,
    TITLE_BUTTON_SEND_TO_APPROVAL_SHORT,
    TITLE_BUTTON_SEND_TO_MANAGER,
    TITLE_DIALOG_PDP_DELETE,
    TITLE_DIALOG_PDP_SEND_TO_REVIEWER,
    TITLE_NAMESPACE_AUTHOR,
    TITLE_NAMESPACE_CREATED_DEVELOPMENT_PLAN,
    TITLE_NAMESPACE_DEVELOPMENT_PLAN,
    TITLE_NAMESPACE_SENT,
    TITLE_NAMESPACE_SENT_FOR_APPROVAL,
} from 'helpers/enums/titles'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { IPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    DialogEditPdp,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/dialogs/DialogEditPdp/DialogEditPdp'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { developmentPdpActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { useNotify } from 'store/hooks/useNotify'
import { biggerBtnForMobile } from 'styles/styles'


type DevelopmentPdpHeaderProps = {
    pdp: IDevelopmentPdp;
    pdpStatus: IPdpStatus;
}

export const DevelopmentPdpHeader: FC<DevelopmentPdpHeaderProps> = ({ pdp, pdpStatus }) => {
    const desktop = useDesktop()
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const navigate = useNavigate()
    const { dialogEdit, dialogDelete, dialogSendPlan, toggleDialog } = useDialogsState({
        dialogDelete: false,
        dialogEdit: false,
        dialogSendPlan: false,
        dialogCancelPlan: false,
    })

    const validDateSentAt = isValidDateFromApi(pdp?.sentAt)
    const validDateApprovalAt = isValidDateFromApi(pdp?.sentForApprovalAt)

    const createdStatus = pdpStatus?.status === 'default'
    const approvalStatus = pdpStatus?.status === 'approval'
    const employeeIsAuthor = pdp?.author?.uuid === pdp?.employee?.uuid

    const onSubmitDelete = async () => {
        try {
            await dispatch(developmentPdpActions.deletePdp(pdp.currentPdpUuid))
            notify(HINT_SUCCESS_PDP_DELETED, 'success')
            navigate(`${PATH_DEVELOPMENT}/pdp`)
        } catch (e: any) {
            notify(e.userMessage, 'error')
            throw e
        }
    }

    const onSendToApproval = async () => {
        try {
            await dispatch(developmentPdpActions.sendToApproval(pdp.currentPdpUuid))
            notify(HINT_SUCCESS_PDP_SENT_TO_APPROVAL, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    return (
        <Box display={!desktop ? 'block' : 'flex'} justifyContent="space-between">
            <DialogConfirmDelete
                open={dialogDelete}
                handleClose={() => toggleDialog('dialogDelete', false)}
                onSubmit={onSubmitDelete}
                title={TITLE_DIALOG_PDP_DELETE}
                helperText={HINT_CHANGES_WILL_LOST}
                elementType={TITLE_NAMESPACE_CREATED_DEVELOPMENT_PLAN.toLowerCase()}
            />
            {dialogEdit &&
                <DialogEditPdp
                    handleClose={() => toggleDialog('dialogEdit', false)}
                    open={dialogEdit}
                />
            }
            <DialogConfirm
                open={dialogSendPlan}
                onSubmit={onSendToApproval}
                dialogTitle={TITLE_DIALOG_PDP_SEND_TO_REVIEWER}
                dialogText={HINT_PDP_SEND_TO_APPROVAL}
                titleButtonConfirm={TITLE_BUTTON_SEND_TO_MANAGER}
                colorButtonConfirm="primary"
                // dialogHelperText={HINT_PDP_SEND_TO_EMPLOYEE_HELPER_TEXT}
                titleButtonCancel={TITLE_BUTTON_CANCEL}
                handleClose={() => toggleDialog('dialogSendPlan', false)}
            />
            <Stack
                spacing={1}
                mb={!desktop ? 3 : 0}
                direction={desktop ? 'row' : 'column'}
                justifyContent={desktop ? 'space-between' : 'flex-start'}
                alignItems='flex-start'
                flex={1}
            >
                <Box width={!desktop ? '100%' : 'auto'}>
                    <Box display="flex" flexDirection={!desktop ? 'column' : 'row'} mb={1}>
                        <Box display="flex" justifyContent='space-between'>
                            <Typography variant={!desktop ? 'h1' : 'h2'} mr={3} mb={!desktop ? 2 : 0}>
                                {TITLE_NAMESPACE_DEVELOPMENT_PLAN}
                            </Typography>
                            {!desktop && createdStatus && employeeIsAuthor &&
                                <SecondaryActions
                                    onEdit={() => toggleDialog('dialogEdit', true)}
                                    onDelete={() => toggleDialog('dialogDelete', true)}
                                />
                            }
                        </Box>
                        {!desktop && !(createdStatus && employeeIsAuthor) &&
                            <Chip
                                label={pdpStatus.title}
                                icon={pdpStatus.chipIcon}
                                color={pdpStatus.color}
                                variant={pdpStatus.chipVariant}
                                sx={{ maxWidth: 'fit-content', mb: 2 }}
                            />
                        }
                        <PointPositionSpecializationGrade title={pdp?.criteria?.name ?? pdp?.pointPosition?.title}/>
                        {createdStatus && employeeIsAuthor && !desktop &&
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<SendRounded/>}
                                onClick={() => toggleDialog('dialogSendPlan', true)}
                                sx={desktop ? {} : {
                                    ...biggerBtnForMobile,
                                    mt: 3,
                                }}
                            >
                                {TITLE_BUTTON_SEND_TO_APPROVAL_SHORT}
                            </Button>
                        }
                    </Box>
                    {desktop &&
                        <Stack spacing={1}>
                            <Stack direction="row" divider={<Divider orientation="vertical" flexItem/>} spacing={1}>
                                {validDateSentAt && !approvalStatus &&
                                    <Typography variant="body1">
                                        {TITLE_NAMESPACE_SENT}: {pdp?.sentAt}
                                    </Typography>
                                }
                                {approvalStatus && validDateApprovalAt &&
                                    <Typography variant="body1">
                                        {TITLE_NAMESPACE_SENT_FOR_APPROVAL}: {pdp?.sentForApprovalAt}
                                    </Typography>
                                }
                                {pdp?.author?.name &&
                                    <Typography variant="body1">
                                        {TITLE_NAMESPACE_AUTHOR}: {pdp?.author?.name}
                                    </Typography>
                                }
                            </Stack>
                            {!!pdp?.changeLog && <BadgeChangeLog changeLog={pdp?.changeLog} chipDateTime/>}
                        </Stack>
                    }
                </Box>
                {desktop &&
                    <Box display="flex" alignItems="center">
                        {createdStatus && employeeIsAuthor ?
                            <Box display="flex" alignItems="center">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SendRounded/>}
                                    sx={{ marginRight: 2 }}
                                    onClick={() => toggleDialog('dialogSendPlan', true)}
                                >
                                    {TITLE_BUTTON_SEND_TO_APPROVAL}
                                </Button>
                                <SecondaryActions
                                    onEdit={() => toggleDialog('dialogEdit', true)}
                                    onDelete={() => toggleDialog('dialogDelete', true)}
                                />
                            </Box>
                            :
                            <Chip
                                label={pdpStatus.title}
                                icon={pdpStatus.chipIcon}
                                color={pdpStatus.color}
                                variant={pdpStatus.chipVariant}
                            />
                        }
                    </Box>
                }
            </Stack>
        </Box>
    )
}

