import { Stack } from '@mui/material'
import {
    AssessmentResultAlertMain,
} from 'components/assessment/AssessmentResult/components/AssessmentResultAlertMain/AssessmentResultAlertMain'
import {
    AssessmentResultAccordance,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultAccordance/AssessmentResultAccordance'
import {
    AssessmentResultActions,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultActions/AssessmentResultActions'
import {
    AssessmentResultAlertPdp,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultAlertPdp/AssessmentResultAlertPdp'
import {
    AssessmentResultComments,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultComments/AssessmentResultComments'
import {
    AssessmentResultSides,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultSides/AssessmentResultSides'
import {
    AssessmentResultGraphicMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultGraphicMobile/AssessmentResultGraphicMobile'
import {
    AssessmentResultHeaderMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultHeaderMobile/AssessmentResultHeaderMobile'
import {
    AssessmentResultTreeMobile,
} from 'components/assessment/AssessmentResult/components/AssessmentResultMobile/components/AssessmentResultTreeMobile/AssessmentResultTreeMobile'
import React, { FC } from 'react'
import { useAppSelector } from 'store/hooks/redux'


type AssessmentResultMobileProps = {
    children?: React.ReactNode;
}

export const AssessmentResultMobile: FC<AssessmentResultMobileProps> = ({ children }) => {
    const graphicData = useAppSelector(state => state.assessmentResultReducer.data.accordanceGraphic)
    return (
        <Stack spacing={4}>
            <AssessmentResultHeaderMobile/>
            <AssessmentResultAlertMain/>
            <AssessmentResultAlertPdp/>
            <AssessmentResultSides/>
            <AssessmentResultComments/>
            {graphicData && <AssessmentResultAccordance/>}
            <AssessmentResultGraphicMobile/>
            <AssessmentResultTreeMobile/>
            {children
                ? children
                : <AssessmentResultActions/>}
        </Stack>
    )
}
