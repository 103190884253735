import { DateRangeRounded, LibraryAddRounded } from '@mui/icons-material'
import { addDays, isPast, parse } from 'date-fns'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { assessmentStatusComplete } from '../../helpers/assessment/getAssessmentStatus'
import { getCpaStatusChip } from '../../helpers/cpa/cpaStatuses'
import { isValidDateFromApi } from '../../helpers/dateAndTime/isValidDateFromApi'
import { MenuItemsCodes } from '../../helpers/enums/menuItemsPosition'
import { ExamStatusCode, getExamStatusChip } from '../../helpers/exam/examStatuses'
import { massExamStatusComplete } from '../../helpers/massExam/massExamStatuses'
import { pdpStatusExpired, pdpStatusInProgress, pdpStatusReturned, pdpStatusSent } from '../../helpers/pdp/getPdpStatus'
import {
    appAction,
    cpaListActions,
    developmentPdpActions,
    employeeActions,
    examListAction,
    massExamListActions,
    myAssessmentActions,
} from '../actions'
import { AlertData } from '../reducers/app/alerts/AlertsSlice'
import { useAuthentication } from './authentication'
import { useAppDispatch, useAppSelector } from './redux'


export enum AlertType {
    NEW = 'new', // добавлено что то новое
    EXPIRE = 'expire', // просроченно
    WAIT = 'wait', // есть что нужно пройти
    ALARM = 'alarm', // ошибки, что то срочное
}

const alertThemes = {
    [AlertType.EXPIRE]: {
        alertType: AlertType.EXPIRE,
        backgroundColor: '#FAF1DF',
        badgeColor: '#DA8300',
    },
    [AlertType.NEW]: {
        alertType: AlertType.NEW,
        backgroundColor: '#FEF2E1',
        badgeColor: '#E76B18',
    },
    [AlertType.WAIT]: {
        alertType: AlertType.WAIT,
        backgroundColor: '#E2F7FF',
        badgeColor: '#0989A5',
    },
    [AlertType.ALARM]: {
        alertType: AlertType.ALARM,
        backgroundColor: '#FFEBEE',
        badgeColor: '#D32F2F',
    },
}

const myAssessmentListsAlertData: {[key in AlertType]: AlertData } = {
    [AlertType.EXPIRE]: {
        bannerTitle: 'Вы не успели пройти некоторые оценки в срок',
        bannerIcon: React.createElement(DateRangeRounded),
        tooltipTitle: 'Есть просроченные',
        ...alertThemes[AlertType.EXPIRE],
    },
    [AlertType.WAIT]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: 'Нужно пройти',
        ...alertThemes[AlertType.WAIT],
    },
    [AlertType.NEW]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: 'Есть новые',
        ...alertThemes[AlertType.NEW],
    },
    [AlertType.ALARM]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: '',
        ...alertThemes[AlertType.ALARM],
    },
}

const myExamListsAlertData: {[key in AlertType]: AlertData} = {
    [AlertType.EXPIRE]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: 'Есть просроченные',
        ...alertThemes[AlertType.EXPIRE],
    },
    [AlertType.WAIT]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: 'Нужно пройти',
        ...alertThemes[AlertType.WAIT],
    },
    [AlertType.NEW]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: 'Есть новые',
        ...alertThemes[AlertType.NEW],
    },
    [AlertType.ALARM]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: '',
        ...alertThemes[AlertType.ALARM],
    },
}

const myPdpAlertData: {[key in AlertType]: AlertData} = {
    [AlertType.EXPIRE]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: 'Просрочен',
        ...alertThemes[AlertType.EXPIRE],
    },
    [AlertType.WAIT]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: 'Есть активный',
        ...alertThemes[AlertType.WAIT],
    },
    [AlertType.NEW]: {
        bannerTitle: 'Вам назначен новый план развития',
        bannerIcon: React.createElement(LibraryAddRounded),
        tooltipTitle: 'Назначен новый',
        ...alertThemes[AlertType.NEW],
    },
    [AlertType.ALARM]: {
        bannerTitle: '',
        bannerIcon: null,
        tooltipTitle: '',
        ...alertThemes[AlertType.ALARM],
    },
}

export const useGetAlertsData = () => {
    const dispatch = useAppDispatch()
    const employeeUuid = useAppSelector(state => state.userReducer.data.uuid)
    const pdp = useAppSelector(state => state.developmentPdpReducer.data.pdp)
    const myAssessment = useAppSelector(state => state.myAssessmentListReducer.data.assessmentMetaList)
    const examList = useAppSelector(state => state.examListReducer.list)
    const employees = useAppSelector(state => state.employeeReducer.data.employeesCurrent)
    const cpa = useAppSelector(state => state.CPAListReducer.list)
    const massExam = useAppSelector(state => state.massExamListSliceReducer.list)

    const getEmployeesPermission = useAuthentication({ service: 'employee', accessLevel: 'r' })
    const getCPAPermission = useAuthentication({ service: 'cpa', accessLevel: 'r' })
    const getMassExamPermission = useAuthentication({ service: 'mass_exam', accessLevel: 'r' })


    useQuery({
        queryKey: 'useAlerts',
        queryFn: () => {
            Promise.allSettled([
                dispatch(developmentPdpActions.getPdp()),
                dispatch(myAssessmentActions.getMyAssessmentMetaList()),
                dispatch(examListAction.getList({ employeeUuid })),
                getEmployeesPermission ? dispatch(employeeActions.getEmployeesCurrent()) : null,
                getCPAPermission ? dispatch(cpaListActions.getList()) : null,
                getMassExamPermission ? dispatch(massExamListActions.getList()) : null,
            ])
        },
        refetchOnWindowFocus: false,
        retry: 0,
        enabled: !!employeeUuid,
    })

    useEffect(() => {
        const isSomeExpired = myAssessment.needToPass?.list.some(item =>
            item.statusUuid !== assessmentStatusComplete &&
            isValidDateFromApi(item?.endedAt) &&
            isPast(addDays(parse(item.endedAt, 'dd.MM.yyyy', new Date()), 1)))

        switch (true) {
            case isSomeExpired: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.MY_ASSESSMENTS,
                    alertData: myAssessmentListsAlertData[AlertType.EXPIRE],
                }))
                break
            }
            case !!myAssessment.needToPass.list.length: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.MY_ASSESSMENTS,
                    alertData: myAssessmentListsAlertData[AlertType.WAIT],
                }))
                break
            }
            default: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.MY_ASSESSMENTS,
                    alertData: null,
                }))
                break
            }
        }
    }, [myAssessment])

    useEffect(() => {
        const isSomeExpired = examList?.some(exam => isValidDateFromApi(exam?.endedAt) &&
            ![ExamStatusCode.DONE, ExamStatusCode.CANCEL].includes(getExamStatusChip(exam.statusUuid).code) &&
            isPast(addDays(parse(exam?.endedAt, 'dd.MM.yyyy', new Date()), 1)),
        )

        const isSomeActive = examList?.some(exam => isValidDateFromApi(exam?.endedAt) &&
            [ExamStatusCode.AWAIT_SELF, ExamStatusCode.TO_PASS].includes(getExamStatusChip(exam.statusUuid).code) &&
            !isPast(addDays(parse(exam?.endedAt, 'dd.MM.yyyy', new Date()), 1)))

        switch (true) {
            case isSomeExpired: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.MY_EXAMS,
                    alertData: myExamListsAlertData[AlertType.EXPIRE],
                }))
                break
            }
            case isSomeActive: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.MY_EXAMS,
                    alertData: myExamListsAlertData[AlertType.WAIT],
                }))
                break
            }
            default: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.MY_EXAMS,
                    alertData: null,
                }))
                break
            }
        }
    }, [examList])

    useEffect(() => {
        switch (true) {
            case pdp?.status?.uuid === pdpStatusExpired: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.DEVELOPMENT,
                    alertData: myPdpAlertData[AlertType.EXPIRE],
                }))
                break
            }
            case [pdpStatusSent, pdpStatusInProgress, pdpStatusReturned].includes(pdp?.status?.uuid || ''): {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.DEVELOPMENT,
                    alertData: myPdpAlertData[AlertType.WAIT],
                }))
                break
            }
            default: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.DEVELOPMENT,
                    alertData: null,
                }))
                break
            }
        }
    }, [pdp])

    useEffect(() => {
        const isSomeExpireByAuthor = cpa?.some(item => {
            return item.author.uuid === employeeUuid &&
                isValidDateFromApi(item?.endedAt) &&
                getCpaStatusChip(item.statusUuid).code !== 'complete' &&
                isPast(addDays(parse(item?.endedAt, 'dd.MM.yyyy', new Date()), 1))
        })
        switch (true) {
            case isSomeExpireByAuthor : {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.CPA,
                    alertData: {
                        bannerTitle: 'Есть оценки с пропущенным сроком завершения',
                        bannerIcon: React.createElement(DateRangeRounded),
                        tooltipTitle: 'Есть просроченные',
                        ...alertThemes[AlertType.EXPIRE],
                    },
                }))
                break
            }
            default: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.CPA,
                    alertData: null,
                }))
                break
            }
        }
    }, [cpa])

    useEffect(() => {
        const isSomeExpireByAuthor = massExam?.some(item => item.author.uuid === employeeUuid &&
            isValidDateFromApi(item?.endedAt) &&
            item.statusUuid !== massExamStatusComplete &&
            isPast(addDays(parse(item?.endedAt, 'dd.MM.yyyy', new Date()), 1)))

        switch (true) {
            case isSomeExpireByAuthor : {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.MASS_EXAM,
                    alertData: {
                        bannerTitle: 'Есть тестирования с пропущенным сроком завершения',
                        bannerIcon: React.createElement(DateRangeRounded),
                        tooltipTitle: 'Есть просроченные',
                        ...alertThemes[AlertType.EXPIRE],
                    },
                }))
                break
            }
            default: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.MASS_EXAM,
                    alertData: null,
                }))
                break
            }
        }
    }, [massExam])

    useEffect(() => {
        const isSomeNeedAction = employees?.some(division => division.employees.some(employee => employee.needAction))

        switch (true) {
            case isSomeNeedAction: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.EMPLOYEES,
                    alertData: {
                        bannerTitle: '',
                        bannerIcon: '',
                        tooltipTitle: '',
                        ...alertThemes[AlertType.EXPIRE],
                    },
                }))
                break
            }
            default: {
                dispatch(appAction.setAlertData({
                    menuItemCode: MenuItemsCodes.EMPLOYEES,
                    alertData: null,
                }))
                break
            }
        }
    }, [employees])
}
