import { AddCommentOutlined, CommentOutlined } from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, Stack, Typography } from '@mui/material'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'
import { GFAccordion } from 'components/common/GFAccordion/GFAccordion'
import { DialogConfirmDelete } from 'components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { GFProgressBar } from 'components/GFProgressBar/GFProgressBar'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import {
    HINT_PDP_ITEM_COMPETENCE_DELETE,
    HINT_SUCCESS_COMMENT_CREATE,
    HINT_SUCCESS_COMMENT_DELETE,
    HINT_SUCCESS_PDP_ITEM_DELETE,
} from 'helpers/enums/hints'
import { PATH_DEVELOPMENT, PATH_HISTORY } from 'helpers/enums/routePath'
import {
    TITLE_COMPETENCE_DESCRIPTION,
    TITLE_DIALOG_PDP_ITEM_DELETE,
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_COMMENTS,
    TITLE_NAMESPACE_COMPETENCE_ACCUSATIVE,
    TITLE_NAMESPACE_PLAN_ITEM,
    TITLE_NAMESPACE_STUDY_TIME,
} from 'helpers/enums/titles'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { useMobile } from 'helpers/hooks/useMobile'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import React, { CSSProperties, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { developmentPdpItemActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { useNotify } from 'store/hooks/useNotify'
import {
    DevelopmentPdpItemViewCompetenceContent,
} from './DevelopmentPdpItemViewCompetenceContent/DevelopmentPdpItemViewCompetenceContent'


export const DevelopmentPdpItemViewCompetence = () => {
    const desktop = useDesktop()
    const mobile = useMobile()
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const navigate = useNavigate()
    const { dialogCommentView, dialogItemDelete, toggleDialog } = useDialogsState({
        dialogCommentView: false,
        dialogItemDelete: false,
    })
    const { pdpUuid, itemUuid } = useParams()
    const { item } = useAppSelector(state => state.developmentPdpItemReducer.data)

    const { isFetching, error } = useLayoutFetch(developmentPdpItemActions.getPdpItem({
        pdpUuid: pdpUuid || '',
        itemUuid: itemUuid || '',
    }), {
        deps: [pdpUuid, itemUuid],
        permissions: { service: 'my_development', accessLevel: 'r' },
    })
    const notifyStyles: CSSProperties = useMemo(() => desktop ? {} : {position: 'inherit', bottom: '110px', width: 'fit-content'}, [])

    const pdpStatus = getPdpStatus(item?.pdpStatus, false)
    const pdpStatusProgress = pdpStatus.status === 'inProgress'
    const pdpStatusReturned = pdpStatus.status === 'returned'
    const pdpStatusSent = pdpStatus.status === 'sent'
    const pdpStatusCreated = pdpStatus.status === 'default'

    const disableEditComments = !(pdpStatusProgress || pdpStatusReturned || pdpStatusSent || pdpStatusCreated)

    const deleteComment = async (uuid: string) => {
        if (!pdpUuid || !itemUuid) return
        try {
            await dispatch(developmentPdpItemActions.deletePdpItemComment({
                pdpUuid,
                itemUuid,
                uuid,
            }))
            notify(HINT_SUCCESS_COMMENT_DELETE, 'success', '', notifyStyles)
        } catch (e: any) {
            notify(e.userMessage, 'error', '', notifyStyles)
        }
    }

    const createComment = async (comment: IComment) => {
        if (!pdpUuid || !itemUuid) return
        try {
            await dispatch(developmentPdpItemActions.createPdpItemComment({
                ...comment,
                pdpUuid,
                itemUuid,
            }))
            notify(HINT_SUCCESS_COMMENT_CREATE, 'success', '', notifyStyles)
        } catch (e: any) {
            notify(e.userMessage, 'error', '', notifyStyles)
        }
    }

    const onDeleteConfirm = async () => {
        if (!pdpUuid) return
        try {
            await dispatch(developmentPdpItemActions.deletePdpItem({
                pdpUuid,
                itemUuid: item?.uuid,
            }))
            await navigate(`${PATH_DEVELOPMENT}/pdp/${pdpUuid}`, { replace: true })
            notify(HINT_SUCCESS_PDP_ITEM_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (isFetching && !item) return <LoadingComponent/>
    if (error) return <Navigate to={`${PATH_HISTORY}/pdp/${pdpUuid}`}/>
    if (!item) return null

    return (
        <Box>
            <DrawerComments
                open={dialogCommentView}
                disableEdit={disableEditComments}
                handleClose={() => toggleDialog('dialogCommentView', false)}
                entityTitle={TITLE_NAMESPACE_PLAN_ITEM}
                itemTitle={item.title}
                comments={item.comments}
                onDeleteComment={deleteComment}
                onCreateComment={createComment}
            />
            <DialogConfirmDelete
                open={dialogItemDelete}
                handleClose={() => toggleDialog('dialogItemDelete', false)}
                onSubmit={onDeleteConfirm}
                title={TITLE_DIALOG_PDP_ITEM_DELETE}
                elementTitle={item?.title}
                helperText={HINT_PDP_ITEM_COMPETENCE_DELETE}
                elementType={TITLE_NAMESPACE_COMPETENCE_ACCUSATIVE}
            />
            <Box>
                <Stack spacing={2} mb={6}>
                    <Box>
                        <Stack direction='row' justifyContent="space-between" alignItems="flex-start">
                            <Typography variant="h2" mb={1}>
                                {item?.title}
                            </Typography>
                            {pdpStatusCreated &&
                                <SecondaryActions
                                    onDelete={() => toggleDialog('dialogItemDelete', true)}
                                />
                            }
                        </Stack>
                        <Stack direction="row" alignItems='center' spacing={1} mb={2}>
                            <Typography variant="body1">
                                {item?.competenceType}
                            </Typography>
                            {!!item?.changeLog &&
                                <BadgeChangeLog
                                    changeLog={item?.changeLog}
                                    evaluatedUuid={item?.employee?.uuid}
                                    badgeDrawerTitle={item?.title}
                                    badgeDrawerSubTitle={item?.competenceType}
                                />
                            }
                        </Stack>
                    </Box>
                    <Box>
                        <Typography variant="body1" mb={1}>
                            {TITLE_NAMESPACE_STUDY_TIME}: {formatHours(item?.studyTime)}
                        </Typography>
                        {desktop ?
                            <Typography variant="body1" maxWidth={800}>
                                {item.description}
                            </Typography>
                            :
                            <>
                                {item.description.length &&
                                    <GFAccordion>
                                        <Stack direction='row' alignItems='center'>
                                            <InfoOutlinedIcon color='primary' sx={{ mr: 1 }} />
                                            <Typography variant='body2'>{TITLE_COMPETENCE_DESCRIPTION}</Typography>
                                        </Stack>
                                        <Typography variant="body1">
                                            {item.description}
                                        </Typography>
                                    </GFAccordion>
                                }
                            </>
                        }
                    </Box>
                    {!pdpStatusCreated &&
                        <Box maxWidth={mobile ? '100%' : 300}>
                            <GFProgressBar value={item.progress}/>
                        </Box>
                    }
                    <Button
                        startIcon={item.comments.length > 0
                            ? <CommentOutlined/>
                            : <AddCommentOutlined/>}
                        variant="outlined"
                        disabled={item.comments.length <= 0 && disableEditComments}
                        sx={desktop ? { alignSelf: 'flex-start' } : {
                            fontSize: 16,
                            lineHeight: '26px',
                            padding: '12px 20px',
                        }}
                        onClick={() => toggleDialog('dialogCommentView', true)}
                    >
                        {item.comments.length > 0 ? TITLE_NAMESPACE_COMMENTS : TITLE_NAMESPACE_COMMENT}
                    </Button>
                </Stack>
            </Box>
            <DevelopmentPdpItemViewCompetenceContent/>
        </Box>
    )
}

