import { AddCommentOutlined, CommentOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Collapse, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { InputForm } from 'components/form/InputForm/InputForm'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { convertLocalStringToUtcString } from 'helpers/dateAndTime/convertLocalStringToUtcString'
import { TITLE_BUTTON_CANCEL, TITLE_BUTTON_SAVE, TITLE_NAMESPACE_COMMENT } from 'helpers/enums/titles'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { myAssessmentActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'


type MyAssessmentIndicatorCommentsProps = {
    indicatorUuid: string;
    competenceUuid: string;
}

export const MyAssessmentIndicatorComments: FC<MyAssessmentIndicatorCommentsProps> = ({
    indicatorUuid,
    competenceUuid,
}) => {
    const { assessmentUuid } = useParams()
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { uuid, firstName, lastName } = useAppSelector(state => state.userReducer.data)

    const assessmentSetup = useAppSelector(state => state.MyAssessmentPassReducer.data.assessmentSetup)
    const indicator = useAppSelector(state => state.MyAssessmentPassReducer.data.indicators[indicatorUuid])
    const initialComment = useMemo(
        () => indicator?.comments.find(el => el.authorUuid === uuid),
        [indicator.comments],
    )

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState(initialComment?.text || '')


    useEffect(() => {
        setComment(initialComment?.text ?? '')
    }, [initialComment])

    const disableForm = !assessmentSetup?.canAddComment

    const changeComment = async () => {
        if (!assessmentUuid || disableForm) return
        const hasOldComment = !!initialComment?.text.length
        const hasNewComment = !!comment.length
        try {
            setLoading(true)
            switch (true) {
                // delete
                case !hasNewComment && hasOldComment: {
                    await deleteComment()
                    break
                }
                // update
                case hasNewComment && hasOldComment: {
                    if (!initialComment?.uuid) return
                    await dispatch(myAssessmentActions.updateIndicatorComment({
                        indicatorUuid,
                        text: comment,
                        authorUuid: uuid,
                        author: `${lastName} ${Array.from(firstName)[0]}.`,
                        createdAt: initialComment.createdAt,
                        updatedAt: convertLocalStringToUtcString(),
                        assessmentUuid,
                        competenceUuid,
                        uuid: initialComment?.uuid,
                    }))
                    notify('Комментарий сохранён успешно', 'success')
                    setOpen(false)
                    break
                }
                // create
                case hasNewComment && !hasOldComment: {
                    await dispatch(myAssessmentActions.createIndicatorComment({
                        indicatorUuid,
                        text: comment,
                        authorUuid: uuid,
                        author: `${lastName} ${Array.from(firstName)[0]}.`,
                        createdAt: convertLocalStringToUtcString(),
                        updatedAt: convertLocalStringToUtcString(),
                        assessmentUuid,
                        competenceUuid,
                        uuid: initialComment?.uuid,
                    }))
                    notify('Комментарий сохранён успешно', 'success')
                    setOpen(false)
                    break
                }
                default: {
                    break
                }
            }
        } catch (e: any) {
            notify(e.userMessage, 'error')
        } finally {
            setLoading(false)
        }
    }

    const deleteComment = async () => {
        if (!initialComment?.uuid || !assessmentUuid) return
        try {
            await dispatch(myAssessmentActions.deleteIndicatorComment({
                indicatorUuid,
                assessmentUuid,
                competenceUuid,
                uuid: initialComment?.uuid,
            }))
            setOpen(false)
            notify('Комментарий удалён успешно', 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const restoreDefault = () => {
        setComment(initialComment?.text || '')
        setOpen(false)
    }

    if (!indicator || !assessmentSetup?.showComment) return null

    return (
        <>
            <Stack spacing={3}>
                {initialComment && !open
                    ? <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            borderRadius: 4,
                            background: (theme) => theme.palette.background.default,
                        }}
                    >
                        {mobile
                            ? <Stack spacing={2}>
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <CommentOutlined color="action"/>
                                    <Typography variant="body1" sx={{ flex: 1 }}>
                                        {TITLE_NAMESPACE_COMMENT}
                                    </Typography>
                                    <SecondaryActions
                                        onDelete={deleteComment}
                                        onEdit={() => setOpen(true)}
                                    />
                                </Stack>
                                <Typography variant="body2">
                                    {comment}
                                </Typography>
                            </Stack>
                            : <Stack direction="row" spacing={2} alignItems="flex-start">
                                <CommentOutlined color="action"/>
                                <Box flex={1} display="flex" justifyContent="space-between">
                                    <Stack spacing={0.5} flex={1}>
                                        <Typography variant="body1">
                                            {TITLE_NAMESPACE_COMMENT}
                                        </Typography>
                                        <Typography variant="body2">
                                            {comment}
                                        </Typography>
                                    </Stack>
                                    <Box>
                                        <SecondaryActions
                                            onDelete={deleteComment}
                                            onEdit={() => setOpen(true)}
                                        />
                                    </Box>
                                </Box>
                            </Stack>
                        }

                    </Paper>
                    : !open
                        ? <Button
                            startIcon={initialComment ? <CommentOutlined/> : <AddCommentOutlined/>}
                            variant="text"
                            disabled={disableForm}
                            sx={{ alignSelf: 'flex-start' }}
                            onClick={() => setOpen(prev => !prev)}
                        >
                            {TITLE_NAMESPACE_COMMENT}
                        </Button>
                        : null}
                <Collapse in={open} timeout={{exit: 0, enter: 300}} unmountOnExit>
                    <Stack spacing={2}>
                        <InputForm
                            id={`comment-${indicatorUuid}`}
                            title={TITLE_NAMESPACE_COMMENT}
                            value={comment}
                            multiline
                            disabled={disableForm}
                            onChange={(e) => setComment(e.target.value as string)}
                        />
                        {!disableForm && <Stack
                            direction={{ 'mobile': 'column', 'laptop': 'row' }}
                            flexWrap="wrap"
                            justifyContent="flex-end"
                            spacing={mobile ? 1 : 2}
                        >
                            <Button
                                variant="text"
                                onClick={restoreDefault}
                            >
                                {TITLE_BUTTON_CANCEL}
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={loading}
                                disabled={(initialComment?.text ?? '') === comment}
                                startIcon={<CommentOutlined/>}
                                onClick={changeComment}
                            >
                                {TITLE_BUTTON_SAVE}
                            </LoadingButton>
                        </Stack>}
                    </Stack>
                </Collapse>
            </Stack>
        </>
    )
}
