import { PreviewRounded } from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { TITLE_BUTTON_PREVIEW_QUESTIONS } from 'helpers/enums/titles'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import {
    CPAEmployeePreviewAssessmentDrawer,
} from 'pages/cpa/CPAPage/components/CPAEmployees/componenta/CPAEmployeesContent/components/CPAEmployeesDivision/components/CPAEmployeesTable/components/CPAEmployeesTableItem/components/CPAEmployeesPreviewAssessment/compomnents/CPAEmployeePreviewAssessmentDrawer/CPAEmployeePreviewAssessmentDrawer'
import React, { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks/redux'


type CPAEmployeesPreviewAssessmentProps = {
    employee: ICpaEmployee | ICpaItemDivisionEmployee;
    size?: 'small' | 'normal';
    hide?: boolean;
}

export const CPAEmployeesPreviewAssessment: FC<CPAEmployeesPreviewAssessmentProps> = ({
    employee,
    hide,
    size = 'small',
}) => {
    const { cpaUuid } = useParams()
    const [open, setOpen] = useState(false)
    const currentCriteriaUuid = useAppSelector(state => state.CPAItemCriteriaReducer.currentCriteriaUuid)
    const currentScaleUuid = useAppSelector(state => state.CPAItemScaleReducer.currentScaleUuid)
    const cpaStatusCode = useAppSelector(state => state.CPAItemReducer.cpaStatusCode)
    const hasCurrentCriteriaUud = useMemo(() => currentCriteriaUuid
      && !isEmptyUuid(currentCriteriaUuid), [currentCriteriaUuid])
    const hasCurrentScaleUuid = useMemo(() => currentScaleUuid && !isEmptyUuid(currentScaleUuid), [currentScaleUuid])

    if (hide || !cpaUuid || cpaStatusCode === 'complete') return null

    return (
        <>
            <CPAEmployeePreviewAssessmentDrawer
                open={open}
                employee={employee}
                cpaUuid={cpaUuid}
                handleClose={() => setOpen(false)}
            />
            <Tooltip
                placement="bottom-start"
                title={hasCurrentCriteriaUud && hasCurrentScaleUuid
                    ? TITLE_BUTTON_PREVIEW_QUESTIONS
                    : 'Для просмотра вопросов необходимо установить критерии оценивания и выбрать шкалу'}
                enterDelay={300}
                enterNextDelay={300}
            >
                <Box>
                    {size === 'small'
                        ? <IconButton
                            disabled={!hasCurrentCriteriaUud || !hasCurrentScaleUuid}
                            color="primary"
                            onClick={() => setOpen(true)}
                        >
                            <PreviewRounded/>
                        </IconButton>
                        : <Button
                            variant="outlined"
                            startIcon={<PreviewRounded/>}
                            disabled={!hasCurrentCriteriaUud || !hasCurrentScaleUuid}
                            onClick={() => setOpen(true)}
                        >
                            {TITLE_BUTTON_PREVIEW_QUESTIONS}
                        </Button>}
                </Box>
            </Tooltip>
        </>
    )
}
