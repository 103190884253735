import { DevelopmentService } from 'api/services/DevelopmentService'
import { FormService } from 'api/services/FormService'
import {
    DevelopmentPdpUpdateUniqueRequest,
    IDevelopmentCreatePdp,
    IDevelopmentPdpAddItemsFromBaseRequest,
    IDevelopmentPdpChangeOrderItemsRequest,
    IDevelopmentPdpChangePointOrDateRequest,
    IDevelopmentPdpCommentCreateRequest,
    IDevelopmentPdpCommentDeleteRequest,
    IDevelopmentPdpCommentUpdateRequest,
    IDevelopmentPdpCreateUniqueRequest,
    IDevelopmentPdpDeleteItemRequest,
    IDevelopmentPdpItemCommentCreateRequest,
    IDevelopmentPdpItemCommentDeleteRequest,
    IDevelopmentPdpItemCommentUpdateRequest,
    IDevelopmentPdpUpdate,
} from 'api/typing/developmentPdpService'
import { developmentPdpSlice } from 'store/reducers/development/pdp/DevelopmentPdpSlice'
import { AppDispatch } from 'store/store'


export class DevelopmentPdpAction {

    private static get api() {
        return new DevelopmentService().developmentPdpService()
    }

    private static get formApiEmployee() {
        return new FormService().employeeFormService()
    }

    public sendToReview(pdpUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const { status } = await DevelopmentPdpAction.api.sendToReview({ pdpUuid })
                dispatch(developmentPdpSlice.actions.changeStatus(status))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public sendToApproval(pdpUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const { status } = await DevelopmentPdpAction.api.sendToApproval({ pdpUuid })
                dispatch(developmentPdpSlice.actions.changeStatus(status))
                dispatch(developmentPdpSlice.actions.setDateSentForApprovalAt())
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getPdp() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.api.getPdp()
                dispatch(developmentPdpSlice.actions.getPdp(data))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createPdpComment(payload: IDevelopmentPdpCommentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const { uuid } = await DevelopmentPdpAction.api.createPdpComment(payload)
                dispatch(developmentPdpSlice.actions.createPdpComment({ ...payload, uuid }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdpComment(payload: IDevelopmentPdpCommentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                void await DevelopmentPdpAction.api.updatePdpComment(payload)
                dispatch(developmentPdpSlice.actions.updatePdpComment(payload))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdpComment(payload: IDevelopmentPdpCommentDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                void await DevelopmentPdpAction.api.deletePdpComment(payload)
                dispatch(developmentPdpSlice.actions.deletePdpComment(payload))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createPdpItemComment(payload: IDevelopmentPdpItemCommentCreateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const { uuid } = await DevelopmentPdpAction.api.createPdpItemComment(payload)
                dispatch(developmentPdpSlice.actions.createPdpItemComment({ ...payload, uuid }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdpItemComment(payload: IDevelopmentPdpItemCommentUpdateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                void await DevelopmentPdpAction.api.updatePdpItemComment(payload)
                dispatch(developmentPdpSlice.actions.updatePdpItemComment(payload))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdpItemComment(payload: IDevelopmentPdpItemCommentDeleteRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                void await DevelopmentPdpAction.api.deletePdpItemComment(payload)
                dispatch(developmentPdpSlice.actions.deletePdpItemComment(payload))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createPdp(payload: IDevelopmentCreatePdp) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.api.createPdp(payload)
                dispatch(developmentPdpSlice.actions.createPdp(data))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
                return data.uuid
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePdp(payload: IDevelopmentPdpUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.api.updatePdp(payload)
                dispatch(developmentPdpSlice.actions.createPdp(data))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
                return data.uuid
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changePdpPointOrDate(payload: IDevelopmentPdpChangePointOrDateRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.api.changePdpPointOrDate(payload)
                dispatch(developmentPdpSlice.actions.changePdpPointOrDate({ ...payload, ...data }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdp(pdpUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                void await DevelopmentPdpAction.api.deletePdp({ pdpUuid })
                dispatch(developmentPdpSlice.actions.deletePdp())
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createUniquePdpItem(payload: IDevelopmentPdpCreateUniqueRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.api.createUniquePdpItem(payload)
                dispatch(developmentPdpSlice.actions.createUniquePdpItem({ ...payload, ...data }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateUniquePdpItem(payload: DevelopmentPdpUpdateUniqueRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.api.updateUniquePdpItem(payload)
                dispatch(developmentPdpSlice.actions.updateUniquePdpItem({ ...payload, ...data }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePdpItem(payload: IDevelopmentPdpDeleteItemRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.api.deletePdpItem(payload)
                dispatch(developmentPdpSlice.actions.deletePdpItem({ ...payload, ...data }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }


    public getCompetenceForPdp(employeeUuid: string, pdpUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.formApiEmployee.getCompetenceForPdp(employeeUuid, pdpUuid)
                dispatch(developmentPdpSlice.actions.getCompetenceForPdp(data))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeActiveCompetence(uuid: string, groupUuid: string, value: boolean) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                dispatch(developmentPdpSlice.actions.changeActiveCompetence({ uuid, groupUuid, value }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
            }
        }
    }

    public changeActiveCompetenceGroup(uuid: string, value: boolean) {
        return (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                dispatch(developmentPdpSlice.actions.changeActiveCompetenceGroup({ uuid, value }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
            }
        }
    }

    public addPdpItemsFromBase(payload: IDevelopmentPdpAddItemsFromBaseRequest) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(developmentPdpSlice.actions.fetching())
                const data = await DevelopmentPdpAction.api.addPdpItemsFromBase(payload)
                dispatch(developmentPdpSlice.actions.addPdpItemsFromBase({ ...payload, ...data }))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public changeOrderPdpItems(payload: IDevelopmentPdpChangeOrderItemsRequest & { newItemsArray: IPdpPlanItem[] }) {
        return async (dispatch: AppDispatch) => {
            try {
                const { newItemsArray, ...data } = payload
                dispatch(developmentPdpSlice.actions.fetching())
                void await DevelopmentPdpAction.api.changeOrderPdpItems(data)
                dispatch(developmentPdpSlice.actions.changeOrderItems(newItemsArray))
                dispatch(developmentPdpSlice.actions.fetchingSuccess())
            } catch (e: any) {
                dispatch(developmentPdpSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public clearState() {
        return (dispatch: AppDispatch) => {
            dispatch(developmentPdpSlice.actions.clearState())
        }
    }

}
