import { DateRangeRounded } from '@mui/icons-material'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { DatePicker } from '@mui/x-date-pickers'
import { IEmployeeCreate } from 'api/typing/employeeTypes'
import { DialogTitle } from 'components/dialogs/DialogTitle/DialogTitle'
import { InputForm } from 'components/form/InputForm/InputForm'
import { InputMask } from 'components/form/InputMask/InputMask'
import { SelectForm } from 'components/form/SelectForm/SelectForm'
import { format, isDate, isValid, isWithinInterval, subYears } from 'date-fns'
import { fieldsRestriction } from 'helpers/enums/fieldsRestriction'
import {
    HINT_CHOOSE,
    HINT_ERROR_EMAIL,
    HINT_ERROR_INCORRECT_DATE,
    HINT_ERROR_REQUIRED,
    HINT_INPUT_TEXT,
} from 'helpers/enums/hints'
import { PATTERN_EMAIL } from 'helpers/enums/regexPatterns'
import {
    TITLE_BUTTON_ADD,
    TITLE_BUTTON_CANCEL,
    TITLE_DIALOG_EMPLOYEE_ADD,
    TITLE_FIELD_DATE_OF_BIRTH,
    TITLE_FIELD_DIVISION,
    TITLE_FIELD_EMAIL,
    TITLE_FIELD_FIRST_NAME,
    TITLE_FIELD_LAST_NAME,
    TITLE_FIELD_SECOND_NAME,
} from 'helpers/enums/titles'
import { removeWhiteSpaceFromObjectElements } from 'helpers/removeWhiteSpaceFromObjectElements'
import React, { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { employeeActions } from 'store/actions'
import { useAppDispatch } from 'store/hooks/redux'
import { useNotify } from 'store/hooks/useNotify'


type DialogAddNewEmployeeProps = {
    divisions: IEntity[];
    handleClose: () => void;
    open: boolean;
    successCreate: (uuid: string) => void;
}

export const DialogAddNewEmployee: FC<DialogAddNewEmployeeProps> = ({
    open,
    handleClose,
    divisions,
    successCreate,
}) => {
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('mobile'))
    const { handleSubmit, control, formState: { errors } } = useForm<Omit<IEmployeeCreate, 'dateOfBirth'>>({
        shouldFocusError: false,
        defaultValues: {
            lastName: '',
            firstName: '',
            secondName: '',
            email: '',
            divisionUuid: '',
        },
        mode: 'onBlur',
    })

    const [dateOfBirth, setDateOfBirth] = useState<DateState>({
        value: null,
        error: null,
    })
    const changeDateOfBirth = (date: Date | null) => {
        setDateOfBirth({
            ...dateOfBirth,
            value: date,
        })
    }

    useEffect(() => {
        if (dateOfBirth.value && isValid(dateOfBirth.value)) checkDateOfBirth()
    }, [dateOfBirth])

    const checkDateOfBirth = () => {
        const { value } = dateOfBirth
        let isValidInterval = false

        if (!value) {
            setDateOfBirth({
                ...dateOfBirth,
                error: HINT_ERROR_REQUIRED,
            })
            return
        }


        const isValidDate = isDate(value) && isValid(value)
        if (isValidDate) {
            isValidInterval = isWithinInterval(value as Date, {
                start: subYears(new Date(), 200),
                end: new Date(),
            })
        }

        setDateOfBirth({
            ...dateOfBirth,
            error: value && (!isValidDate || !isValidInterval) ? HINT_ERROR_INCORRECT_DATE : null,
        })
    }

    const onSubmit = async (data: Omit<IEmployeeCreate, 'dateOfBirth'>) => {
        checkDateOfBirth()
        if (dateOfBirth.error || !dateOfBirth.value) return
        const payload = removeWhiteSpaceFromObjectElements(data)
        try {
            const { uuid } = await dispatch(employeeActions.addNewEmployee({
                ...payload,
                dateOfBirth: format(new Date(dateOfBirth.value), 'dd.MM.yyyy'),
            }))
            successCreate(uuid)
            await handleClose()
        } catch (e: any) {
            notify(e.userMessage, 'error')
            throw e
        }
    }


    return (
        <Dialog
            fullScreen={mobile}
            open={open}
            onClose={handleClose}
            fullWidth sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        >
            <DialogTitle onClose={handleClose}>
                {TITLE_DIALOG_EMPLOYEE_ADD}
            </DialogTitle>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box>
                        <Box mb={2.5}>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{
                                    ...fieldsRestriction.employees.employee.lastName,
                                }}
                                render={({ field }) => (
                                    <InputMask
                                        {...field}
                                        mask={/^[A-Za-zА-я-]+$/}
                                        id="lastName"
                                        name="lastName"
                                        required
                                        autoComplete="family-name"
                                        inputProps={{
                                            maxLength: fieldsRestriction
                                                .employees
                                                .employee
                                                .lastName
                                                .maxLength
                                                .value,
                                        }}
                                        title={TITLE_FIELD_LAST_NAME}
                                        placeholder={HINT_INPUT_TEXT}
                                        error={!!errors.lastName}
                                        helperText={errors?.lastName ? errors.lastName.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{
                                    ...fieldsRestriction.employees.employee.firstName,
                                }}
                                render={({ field }) => (
                                    <InputMask
                                        {...field}
                                        mask={/^[A-Za-zА-я-]+$/}
                                        id="firstName"
                                        name="firstName"
                                        required
                                        autoComplete="given-name"
                                        inputProps={{
                                            maxLength: fieldsRestriction
                                                .employees
                                                .employee
                                                .firstName
                                                .maxLength
                                                .value,
                                        }}
                                        placeholder={HINT_INPUT_TEXT}
                                        title={TITLE_FIELD_FIRST_NAME}
                                        error={!!errors.firstName}
                                        helperText={errors?.firstName ? errors.firstName.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <Controller
                                name="secondName"
                                control={control}
                                rules={{
                                    ...fieldsRestriction.employees.employee.secondName,
                                }}
                                render={({ field }) => (
                                    <InputMask
                                        {...field}
                                        mask={/^[A-Za-zА-я-]+$/}
                                        id="secondName"
                                        name="secondName"
                                        autoComplete="additional-name"
                                        inputProps={{
                                            maxLength: fieldsRestriction
                                                .employees
                                                .employee
                                                .secondName
                                                .maxLength
                                                .value,
                                        }}
                                        title={TITLE_FIELD_SECOND_NAME}
                                        placeholder={HINT_INPUT_TEXT}
                                        error={!!errors.secondName}
                                        helperText={errors?.secondName ? errors.secondName.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <DatePicker
                                inputFormat="dd.MM.yyyy"
                                value={dateOfBirth.value}
                                toolbarPlaceholder={HINT_CHOOSE}
                                components={{
                                    OpenPickerIcon: DateRangeRounded,
                                }}
                                onChange={changeDateOfBirth}
                                minDate={subYears(new Date(), 200)}
                                maxDate={new Date()}
                                renderInput={(params) => <InputForm
                                    {...params as any}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: HINT_CHOOSE,
                                    }}
                                    onBlur={checkDateOfBirth}
                                    error={!!dateOfBirth.error}
                                    helperText={dateOfBirth.error ? dateOfBirth.error : null}
                                    title={TITLE_FIELD_DATE_OF_BIRTH}
                                />}
                            />
                        </Box>
                        <Box mb={2.5}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                    pattern: {
                                        value: PATTERN_EMAIL,
                                        message: HINT_ERROR_EMAIL,
                                    },
                                }}
                                render={({ field }) => (
                                    <InputForm
                                        {...field}
                                        id="email"
                                        title={TITLE_FIELD_EMAIL}
                                        required
                                        autoComplete="email"
                                        placeholder={HINT_INPUT_TEXT}
                                        error={!!errors.email}
                                        helperText={errors?.email ? errors.email.message : null}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Controller
                                name="divisionUuid"
                                control={control}
                                rules={{
                                    required: HINT_ERROR_REQUIRED,
                                }}
                                render={({ field }) => (
                                    <SelectForm
                                        {...field}
                                        id="divisionUuid"
                                        name="divisionUuid"
                                        required
                                        values={divisions}
                                        title={TITLE_FIELD_DIVISION}
                                        placeholder={HINT_CHOOSE}
                                        error={!!errors.divisionUuid}
                                        helperText={errors?.divisionUuid ? errors.divisionUuid.message : null}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ padding: 3, ...(mobile && { m: 0, flexDirection: 'column-reverse', gap: 1 }) }}
                    disableSpacing={mobile}
                >
                    <Button variant="text" onClick={handleClose}>{TITLE_BUTTON_CANCEL}</Button>
                    <Button variant="contained" type="submit">{TITLE_BUTTON_ADD}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
