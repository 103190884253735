import { FormatListBulletedRounded, HighlightAltRounded } from '@mui/icons-material'
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Authenticated } from 'components/auth/Authenticated/Authenticated'
import { AddButton } from 'components/common/AddButton/AddButton'
import { GFPopoverOnSkeleton } from 'components/common/GFPopoverOnSkeleton/GFPopoverOnSkeleton'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { Loading } from 'components/Loading/Loading'
import { PageTitle } from 'components/PageTitle/PageTitle'
import {
    HINT_DEVELOPMENT_EMPTY_PDP_NOT_EXIST_OLD,
    HINT_EMPTY_PAGE_CURRENT_POSITION,
    HINT_EMPTY_PAGE_PDP_CREATING,
    HINT_EMPTY_PAGE_PDP_WANT_TO_CONTINUE,
    HINT_EMPTY_PAGE_PDP_WANT_TO_CONTINUE_MOBILE,
} from 'helpers/enums/hints'
import { PATH_DEVELOPMENT, PATH_HISTORY } from 'helpers/enums/routePath'
import {
    TITLE_BUTTON_CREATE,
    TITLE_BUTTON_CREATE_PDP,
    TITLE_BUTTON_OPEN_LATEST_PDP,
    TITLE_BUTTON_OPEN_LATEST_PDP_MOBILE,
    TITLE_EMPTY_PAGE_DEVELOPMENT_PDP_NOT_OLD_EXIST,
    TITLE_EMPTY_PAGE_HAS_NOT_CURRENT_POSITION,
    TITLE_EMPTY_PAGE_PDP_CREATING,
    TITLE_EMPTY_PAGE_WANT_TO_CONTINUE,
    TITLE_PAGE_DEVELOPMENT,
} from 'helpers/enums/titles'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    DialogCreatePdp,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/dialogs/DialogCreatePdp/DialogCreatePdp'
import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useLockBodyScroll, useToggle } from 'react-use'
import { developmentPdpActions } from 'store/actions'
import { useAppSelector } from 'store/hooks/redux'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { biggerBtnForMobile } from 'styles/styles'
import { DevelopmentPdp } from './DevelopmentPdp/DevelopmentPdp'
import { PdpSkeleton } from './PdpSkeleton/PdpSkeleton'


export const DevelopmentPage = () => {
    const desktop =  useDesktop()
    // TODO: временное решение, нужно привести весь проект к одним брейкпоинтам, laptop еще использует десктопную версию
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'))
    const navigate = useNavigate()
    const { dialogCreate, toggleDialog } = useDialogsState({ dialogCreate: false })
    const [locked, toggleLocked] = useToggle(false)
    const location = useLocation()
    const ref = React.useRef(null)
    const { pdp } = useAppSelector(state => state.developmentPdpReducer.data)
    const { specialization, grade } = useAppSelector(state => state.userReducer.data)
    const { isFetching, error } = useLayoutFetch(developmentPdpActions.getPdp(), {
        permissions: { service: 'my_development', accessLevel: 'r' },
    })
    useLockBodyScroll(locked, ref)
    const blockedSection = !specialization?.uuid || !grade?.uuid
    const currentPdpStatus = getPdpStatus(pdp?.status)
    const authorPdpIsEmployee = pdp?.author?.uuid && pdp?.employee?.uuid && (pdp?.author?.uuid === pdp?.employee?.uuid)
    const hasPreviousPdp = !isEmptyUuid(pdp?.previousPdpUuid)
    const hasCurrentPdp = !isEmptyUuid(pdp?.currentPdpUuid)
    const pdpStatusCreated = currentPdpStatus.status === 'default'
    const hasPdpFromManager = hasCurrentPdp && pdpStatusCreated && !authorPdpIsEmployee
    const showPdp = hasCurrentPdp && ((pdpStatusCreated && authorPdpIsEmployee) || (!pdpStatusCreated))
    const showEmptyPage = !hasCurrentPdp || !showPdp
    const canCreateNewPdp = isEmptyUuid(pdp?.currentPdpUuid)
    const getEmptyPageData = ({ blockedSection, hasPreviousPdp, hasPdpFromManager, canCreateNewPdp }: {
        blockedSection: boolean;
        hasPreviousPdp: boolean;
        hasPdpFromManager: boolean;
        canCreateNewPdp: boolean;
    }) => {
        switch (true) {
            case blockedSection: {
                return {
                    title: TITLE_EMPTY_PAGE_HAS_NOT_CURRENT_POSITION,
                    text: HINT_EMPTY_PAGE_CURRENT_POSITION,
                    icon: <HighlightAltRounded color="primary" sx={{ fontSize: 100 }}/>,
                }
            }
            case hasPdpFromManager: {
                return {
                    title: TITLE_EMPTY_PAGE_PDP_CREATING,
                    text: HINT_EMPTY_PAGE_PDP_CREATING,
                    icon: <HighlightAltRounded color="primary" sx={{ fontSize: 100 }}/>,
                }
            }
            case canCreateNewPdp && hasPreviousPdp: {
                return {
                    title: TITLE_EMPTY_PAGE_WANT_TO_CONTINUE,
                    text: desktop ? HINT_EMPTY_PAGE_PDP_WANT_TO_CONTINUE : HINT_EMPTY_PAGE_PDP_WANT_TO_CONTINUE_MOBILE,
                    icon: <FormatListBulletedRounded color="primary" sx={{ fontSize: 100 }}/>,
                }
            }
            case canCreateNewPdp && !hasPreviousPdp: {
                return {
                    title: TITLE_EMPTY_PAGE_DEVELOPMENT_PDP_NOT_OLD_EXIST,
                    text: HINT_DEVELOPMENT_EMPTY_PDP_NOT_EXIST_OLD,
                    icon: <HighlightAltRounded color="primary" sx={{ fontSize: 100 }}/>,
                }
            }
            default: {
                return null
            }
        }
    }

    const emptyPageState = getEmptyPageData({
        blockedSection,
        hasPreviousPdp,
        hasPdpFromManager,
        canCreateNewPdp,
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!isFetching && hasCurrentPdp && !location.pathname.includes('item'))
            navigate(`${PATH_DEVELOPMENT}/pdp/${pdp?.currentPdpUuid}`)

        if (!hasCurrentPdp && !isFetching || (isFetching || !hasCurrentPdp)) {
            toggleLocked(true)
        } else {
            toggleLocked(false)
        }

        return () => toggleLocked(false)
    }, [pdp, isFetching, hasCurrentPdp, location.pathname])


    const goToLatestPdp = () => {
        navigate(`${PATH_HISTORY}/pdp/${pdp.previousPdpUuid}`)
    }

    if (error) return <ErrorPage error={error} />

    return (
        <Box
            ref={ref}
            overflow={showEmptyPage && emptyPageState && !isFetching ? 'hidden' : 'unset'}
            height={showEmptyPage && emptyPageState && !isFetching ? '100%' : 'auto'}
        >
            <Authenticated service="my_development" accessLevel="w">
                {dialogCreate ?
                    <DialogCreatePdp
                        employeeCreator
                        open={dialogCreate}
                        handleClose={() => toggleDialog('dialogCreate', false)}
                    />
                    :
                    undefined
                }
            </Authenticated>
            {!isMobile &&
                <>
                    <Box mb={6}>
                        <PageTitle title={TITLE_PAGE_DEVELOPMENT}/>
                    </Box>
                    {isFetching || showEmptyPage ? <PdpSkeleton isFetching={isFetching}/> : null}
                </>
            }
            {showEmptyPage && emptyPageState && !isFetching &&
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100vh"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <GFPopoverOnSkeleton
                        text={emptyPageState.text}
                        title={emptyPageState.title}
                        icon={<FormatListBulletedRounded color="primary" sx={{ fontSize: 100 }}/>}
                    >
                        {!blockedSection && pdp &&
                            <Stack spacing={3} justifyContent="center" width={!desktop ? '100%' : 'auto'}>
                                {canCreateNewPdp &&
                                    <Authenticated service="my_development" accessLevel="w">
                                        <AddButton
                                            variant="contained"
                                            fullWidth
                                            sx={desktop ? {} : biggerBtnForMobile}
                                            onClick={() => toggleDialog('dialogCreate', true)}
                                        >
                                            {desktop ? TITLE_BUTTON_CREATE_PDP : TITLE_BUTTON_CREATE}
                                        </AddButton>
                                    </Authenticated>
                                }
                                {hasPreviousPdp &&
                                    <Button
                                        variant="outlined"
                                        onClick={goToLatestPdp}
                                        sx={desktop ? {} : { ...biggerBtnForMobile, width: '100%' }}
                                    >
                                        {desktop ? TITLE_BUTTON_OPEN_LATEST_PDP : TITLE_BUTTON_OPEN_LATEST_PDP_MOBILE}
                                    </Button>
                                }
                            </Stack>
                        }
                    </GFPopoverOnSkeleton>
                </Box>
            }
            {!isFetching && showPdp &&
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path=":pdpUuid/*" element={<DevelopmentPdp pdp={pdp}/>}/>
                    </Routes>
                </Suspense>
            }
        </Box>
    )
}

