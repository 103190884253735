import { EmployeeService } from 'api/services/EmployeeService'
import { FilterService } from 'api/services/FilterService'
import { FormService } from 'api/services/FormService'
import { UploadService } from 'api/services/UploadService'
import {
    IEmployeeCreate,
    IEmployeeEditEducationCourseCreateOrUpdate,
    IEmployeeEditEducationEducationCreateOrUpdate,
    IEmployeePersonalDataUpdate,
    IEmployeeWorkExperiencePreviousJobCreateOrUpdate,
    IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate,
    IEmployeeWorkInCompanyCurrentPositionCreateOrUpdate,
    IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate,
    IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate,
    IEmployeeWorkInCompanyWorkExperienceCreateOrUpdate,
} from 'api/typing/employeeTypes'
import { format, max, min, parse } from 'date-fns'
import { isEmptyUuid } from 'helpers/isEmptyUuid'
import { omitObject } from 'helpers/omitObject'
import {
    employeeEditPersonalDataSlice,
} from 'store/reducers/employee/edit/tabs/editPersonalData/EmployeeEditPersonalDataSlice'
import {
    employeeEditEducationCoursesSlice,
} from 'store/reducers/employee/edit/tabs/education/EmployeeEditEducationCoursesSlice'
import {
    employeeEditEducationEducationSlice,
} from 'store/reducers/employee/edit/tabs/education/EmployeeEditEducationEducationSlice'
import {
    employeeEditWorkExperiencePreviousJobSlice,
} from 'store/reducers/employee/edit/tabs/workExperience/EmployeeEditWorkExperiencePreviousJobSlice'
import {
    employeeEditWorkExperienceProjectsAndRolesSlice,
} from 'store/reducers/employee/edit/tabs/workExperience/EmployeeEditWorkProjectsAndRolesSlice'
import {
    employeeEditWorkInCompanyCurrentPositionSlice,
} from 'store/reducers/employee/edit/tabs/workInCompany/EmployeeEditWorkInCompanyCurrentPositionSlice'
import {
    employeeEditWorkInCompanyPreviousPositionSlice,
} from 'store/reducers/employee/edit/tabs/workInCompany/EmployeeEditWorkInCompanyPreviousPositionSlice'
import {
    employeeEditWorkInCompanyProjectsAndRolesSlice,
} from 'store/reducers/employee/edit/tabs/workInCompany/EmployeeEditWorkInCompanyProjectsAndRolesSlice'
import {
    employeeEditWorkInCompanyWorkExperienceSlice,
} from 'store/reducers/employee/edit/tabs/workInCompany/EmployeeEditWorkInCompanySlice'
import {
    employeeViewCompetenciesSlice,
} from 'store/reducers/employee/view/tabs/competencies/EmployeeViewCompetenciesSlice'
import { employeeViewHistorySlice } from 'store/reducers/employee/view/tabs/history/EmployeeViewHistorySlice'
import { employeeSlice } from 'store/reducers/EmployeeSlice'
import { AppDispatch } from 'store/store'


export class EmployeeAction {
    private static get employeesApi() {
        return new EmployeeService().employeeListService()
    }

    private static get employeeEditApi() {
        return new EmployeeService().employeeEditService()
    }

    private static get employeeViewApi() {
        return new EmployeeService().employeeViewService()
    }

    private static get employeeFormApi() {
        return new FormService().employeeFormService()
    }

    private static get employeeFilterApi() {
        return new FilterService().employeeFilterService()
    }

    private static get employeeUploadApi() {
        return new UploadService().employeeUploadService()
    }

    public employeeGet(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getEmployee(uuid)
                dispatch(employeeSlice.actions.employeeGet(data))
                dispatch(employeeEditPersonalDataSlice.actions.getEmployeePersonalData(data))
            } catch (e: any) {
                dispatch(employeeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateEmployee(data: Partial<IEmployee>) {
        return (dispatch: AppDispatch) => {
            dispatch(employeeSlice.actions.employeeUpdate(data))
        }
    }

    public clearStateEmployee() {
        return (dispatch: AppDispatch) => {
            dispatch(employeeSlice.actions.clearState())
        }
    }

    // TODO: temporary params, need delete when was created chains
    public employeeSalaryGet(uuid: string, params: { specializationUuid: string | null, gradeUuid: string | null }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getEmployeeSalary(uuid, params)
                dispatch(employeeSlice.actions.employeeSalary(data))
            } catch (e: any) {
                dispatch(employeeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeesCurrent() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeSlice.actions.fetching())
                const data = await EmployeeAction.employeesApi.getEmployeesCurrent()
                dispatch(employeeSlice.actions.getEmployeesCurrent(data))
            } catch (e: any) {
                dispatch(employeeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeesDivisions() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeSlice.actions.fetching())
                const data = await EmployeeAction.employeeFormApi.getEmployeeListDivisions()
                dispatch(employeeSlice.actions.getEmployeesDivisions(data))
            } catch (e: any) {
                dispatch(employeeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public addNewEmployee(payload: IEmployeeCreate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeSlice.actions.fetching())
                const data = await EmployeeAction.employeesApi.addNewEmployee(payload)
                dispatch(employeeSlice.actions.addEmployee({
                    uuid: data.uuid,
                    ...payload,
                }))
                return data
            } catch (e: any) {
                dispatch(employeeSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeePersonalData(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditPersonalDataSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getEmployeePersonalData(uuid)
                dispatch(employeeEditPersonalDataSlice.actions.getEmployeePersonalData(data))
            } catch (e: any) {
                dispatch(employeeEditPersonalDataSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateEmployeePersonalData(payload: IEmployeePersonalDataUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditPersonalDataSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.updateEmployeePersonalData(payload)
                dispatch(employeeEditPersonalDataSlice.actions.updateEmployeePersonalData(payload))
                dispatch(employeeSlice.actions.updateEmployee(payload))
            } catch (e: any) {
                dispatch(employeeEditPersonalDataSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public uploadEmployeePhoto(payload: { image: number[], imageType: string, employeeUuid: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditPersonalDataSlice.actions.fetching())
                const { path: photo } = await EmployeeAction.employeeUploadApi.uploadEmployeePhoto(payload)
                dispatch(employeeEditPersonalDataSlice.actions.updateEmployeePhoto(photo))
                dispatch(employeeSlice.actions.updateEmployee({ photo }))
            } catch (e: any) {
                dispatch(employeeEditPersonalDataSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Work in company----------//

    //----------Work experience----------//

    public getEmployeeWorkInCompanyWorkExperience(employeeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyWorkExperienceSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getEmployeeWorkInCompanyWorkExperience(employeeUuid)
                dispatch(employeeEditWorkInCompanyWorkExperienceSlice.actions.getWorkExperience(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyWorkExperienceSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateEmployeeWorkInCompanyWorkExperience(
        employeeUuid: string,
        payload: IEmployeeWorkInCompanyWorkExperienceCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyWorkExperienceSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.updateEmployeeWorkInCompanyWorkExperience(
                    employeeUuid,
                    payload,
                )
                dispatch(employeeEditWorkInCompanyWorkExperienceSlice.actions.updateWorkExperience(
                    omitObject(payload, ['employeeUuid']),
                ))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyWorkExperienceSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Current position----------//
    public getEmployeeWorkInCompanyCurrentPosition(uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getEmployeeWorkInCompanyCurrentPosition(uuid)
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.setCurrentPosition(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateEmployeeWorkInCompanyCurrentPosition(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyCurrentPositionCreateOrUpdate,
        previousPosition: IEmployeeWorkInCompanyCurrentPosition,
        currentPosition: Pick<IEmployee, 'specialization' | 'division' | 'grade'>,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetching())

                // const endDatePreviousPosition = format(previousPosition?.endDate
                //     ? min([parse(previousPosition.endDate, 'dd.MM.yyyy', new Date()), new Date()])
                //     : new Date(), 'dd.MM.yyyy')
                const startDatePreviousPosition = previousPosition.startDate && previousPosition.startDate.length > 0 ? parse(previousPosition.startDate, 'dd.MM.yyyy', new Date()) : new Date()

                const endDatePreviousPosition = format(previousPosition?.endDate
                    ? min([parse(previousPosition.endDate, 'dd.MM.yyyy', new Date()), new Date()])
                    : max([
                        startDatePreviousPosition,
                        previousPosition.endDate && previousPosition.endDate.length > 0 ? parse(previousPosition.endDate, 'dd.MM.yyyy', new Date()) : new Date(),
                        new Date(),
                    ]),
                'dd.MM.yyyy')

                const { previousUuid } = await EmployeeAction
                    .employeeEditApi
                    .updateEmployeeWorkInCompanyCurrentPosition(employeeUuid, data)

                dispatch(employeeSlice.actions.updateEmployee(currentPosition))
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.updateCurrentPosition(data))

                // TODO: if hasnt dateStart, dont adding to previous position
                previousUuid && !isEmptyUuid(previousUuid) &&
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.pushPreviousPositionItem({
                    uuid: previousUuid,
                    ...previousPosition,
                    endDate: endDatePreviousPosition,
                    startDate: format(startDatePreviousPosition, 'dd.MM.yyyy'),
                }))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeEditWorkInCompanyCurrentPositionDivisions() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetching())
                const data = await EmployeeAction
                    .employeeFormApi
                    .getEmployeeEditWorkInCompanyCurrentPositionDivisions()
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.getDivisions(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeEditWorkInCompanyCurrentPositionSpecializations(divisionUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetching())
                const data = await EmployeeAction
                    .employeeFormApi
                    .getEmployeeEditWorkInCompanyCurrentPositionSpecializations(divisionUuid)
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.getSpecializations(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeEditWorkInCompanyCurrentPositionGrades(specializationUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetching())
                const data = await EmployeeAction
                    .employeeFormApi
                    .getEmployeeEditWorkInCompanyCurrentPositionGrades(specializationUuid)
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.getGrades(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Current position----------//

    //----------Previous position----------//

    public getEmployeeWorkInCompanyPreviousPosition(employeeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getEmployeeWorkInCompanyPreviousPosition(employeeUuid)
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.getPreviousPositionList(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateEmployeeWorkInCompanyPreviousPosition(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.updateEmployeeWorkInCompanyPreviousPosition(
                    employeeUuid,
                    data,
                )
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.updatePreviousPositionItem(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createEmployeeWorkInCompanyPreviousPosition(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyPreviousPositionCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetching())
                const { uuid } = await EmployeeAction.employeeEditApi.createEmployeeWorkInCompanyPreviousPosition(
                    employeeUuid,
                    data,
                )
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.createPreviousPositionItem({
                    ...data,
                    uuid,
                }))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteEmployeeWorkInCompanyPreviousPosition(
        employeeUuid: string,
        positionUuid: string,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.deleteEmployeeWorkInCompanyPreviousPosition(
                    employeeUuid,
                    positionUuid,
                )
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.deletePreviousPositionItem(
                    positionUuid,
                ))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeEditWorkInCompanyPreviousPositionDivisions() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetching())
                const data = await EmployeeAction
                    .employeeFormApi
                    .getEmployeeEditWorkInCompanyPreviousPositionDivisions()
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.getDivisions(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeEditWorkInCompanyPreviousPositionSpecializations(divisionUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetching())
                const data = await EmployeeAction
                    .employeeFormApi
                    .getEmployeeEditWorkInCompanyPreviousPositionSpecializations(divisionUuid)
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.getSpecializations(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeEditWorkInCompanyPreviousPositionGrades(specializationUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetching())
                const data = await EmployeeAction
                    .employeeFormApi
                    .getEmployeeEditWorkInCompanyPreviousPositionGrades(specializationUuid)
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.getGrades(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Previous position----------//

    //----------Projects and Roles----------//

    public getEmployeeWorkInCompanyProjectsAndRoles(employeeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getEmployeeWorkInCompanyProjectsAndRoles(employeeUuid)
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.getProjectsAndRolesList(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateEmployeeWorkInCompanyProjectsAndRoles(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.updateEmployeeWorkInCompanyProjectsAndRoles(
                    employeeUuid,
                    data,
                )
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.updateProjectsAndRolesItem(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }


    public createEmployeeWorkInCompanyProjectsAndRoles(
        employeeUuid: string,
        data: IEmployeeWorkInCompanyProjectsAndRolesCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetching())
                const { uuid } = await EmployeeAction.employeeEditApi.createEmployeeWorkInCompanyProjectsAndRoles(
                    employeeUuid,
                    data,
                )
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.createProjectsAndRolesItem({
                    ...data,
                    uuid,
                }))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteEmployeeWorkInCompanyProjectsAndRoles(
        employeeUuid: string,
        projectAndRoleUuid: string,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.deleteEmployeeWorkInCompanyProjectsAndRoles(
                    employeeUuid,
                    projectAndRoleUuid,
                )
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.deleteProjectsAndRolesItem(
                    projectAndRoleUuid,
                ))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeEditWorkInCompanyProjectsAndRolesProjects() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetching())
                const data = await EmployeeAction
                    .employeeFormApi
                    .getEmployeeEditWorkInCompanyProjectsAndRolesProjects()
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.getProjects(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeEditWorkInCompanyProjectsAndRolesRoles() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetching())
                const data = await EmployeeAction
                    .employeeFormApi
                    .getEmployeeEditWorkInCompanyProjectsAndRolesRoles()
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.getRoles(data))
            } catch (e: any) {
                dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Projects and Roles----------//

    //----------Work in company----------//
    //----------Work experience----------//
    //----------Previous jobs----------//
    public getPreviousJobs(employeeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getPreviousJobs(employeeUuid)
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.getPreviousJobs(data))
            } catch (e: any) {
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createPreviousJobs(employeeUuid: string, payload: IEmployeeWorkExperiencePreviousJobCreateOrUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.fetching())
                const data = await EmployeeAction.employeeEditApi.createPreviousJobs(employeeUuid, payload)
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.createPreviousJobs({
                    ...data,
                    ...payload,
                }))
            } catch (e: any) {
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePreviousJobs(employeeUuid: string, payload: IEmployeeWorkExperiencePreviousJobCreateOrUpdate) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.updatePreviousJobs(employeeUuid, payload)
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.updatePreviousJobs(payload))
            } catch (e: any) {
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePreviousJobs(employeeUuid: string, uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.deletePreviousJobs(employeeUuid, uuid)
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.deletePreviousJobs(uuid))
            } catch (e: any) {
                dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Previous jobs----------//

    //----------Projects and roles----------//
    public getPreviousProjectAndRoles(employeeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getPreviousProjectAndRoles(employeeUuid)
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.getPreviousProjectAndRoles(data))
            } catch (e: any) {
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createPreviousProjectAndRoles(
        employeeUuid: string,
        payload: IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.fetching())
                const data = await EmployeeAction.employeeEditApi.createPreviousProjectAndRoles(employeeUuid, payload)
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.createPreviousProjectAndRoles({
                    ...data,
                    ...payload,
                }))
            } catch (e: any) {
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updatePreviousProjectAndRoles(
        employeeUuid: string,
        payload: IEmployeeWorkExperiencePreviousProjectsAndRolesCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.updatePreviousProjectAndRoles(employeeUuid, payload)
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.updatePreviousProjectAndRoles(payload))
            } catch (e: any) {
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deletePreviousProjectAndRoles(employeeUuid: string, uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.deletePreviousProjectAndRoles(employeeUuid, uuid)
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.deletePreviousProjectAndRoles(uuid))
            } catch (e: any) {
                dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Projects and roles----------//
    //----------Work experience----------//

    //----------Education----------//
    //----------Education-card----------//
    public getEducationList(employeeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationEducationSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getEducationList(employeeUuid)
                dispatch(employeeEditEducationEducationSlice.actions.getEducationList(data))
            } catch (e: any) {
                dispatch(employeeEditEducationEducationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createEducation(
        employeeUuid: string,
        payload: IEmployeeEditEducationEducationCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationEducationSlice.actions.fetching())
                const data = await EmployeeAction.employeeEditApi.createEducation(employeeUuid, payload)
                dispatch(employeeEditEducationEducationSlice.actions.createEducation({
                    ...data,
                    ...payload,
                }))
            } catch (e: any) {
                dispatch(employeeEditEducationEducationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateEducation(
        employeeUuid: string,
        payload: IEmployeeEditEducationEducationCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationEducationSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.updateEducation(employeeUuid, payload)
                dispatch(employeeEditEducationEducationSlice.actions.updateEducation(payload))
            } catch (e: any) {
                dispatch(employeeEditEducationEducationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteEducation(employeeUuid: string, uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationEducationSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.deleteEducation(employeeUuid, uuid)
                dispatch(employeeEditEducationEducationSlice.actions.deleteEducation(uuid))
            } catch (e: any) {
                dispatch(employeeEditEducationEducationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEducationLevelList() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationEducationSlice.actions.fetching())
                const data = await EmployeeAction.employeeFormApi.getEmployeeEditEducationLevels()
                dispatch(employeeEditEducationEducationSlice.actions.getEducationLevelList(data))
            } catch (e: any) {
                dispatch(employeeEditEducationEducationSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Education-card----------//

    //----------Courses-card----------//
    public getCourseList(employeeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationCoursesSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getCourseList(employeeUuid)
                dispatch(employeeEditEducationCoursesSlice.actions.getCourseList(data))
            } catch (e: any) {
                dispatch(employeeEditEducationCoursesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public createCourse(
        employeeUuid: string,
        payload: IEmployeeEditEducationCourseCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationCoursesSlice.actions.fetching())
                const data = await EmployeeAction.employeeEditApi.createCourse(employeeUuid, payload)
                dispatch(employeeEditEducationCoursesSlice.actions.createCourse({
                    ...data,
                    ...payload,
                }))
            } catch (e: any) {
                dispatch(employeeEditEducationCoursesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public updateCourse(
        employeeUuid: string,
        payload: IEmployeeEditEducationCourseCreateOrUpdate,
    ) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationCoursesSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.updateCourse(employeeUuid, payload)
                dispatch(employeeEditEducationCoursesSlice.actions.updateCourse(payload))
            } catch (e: any) {
                dispatch(employeeEditEducationCoursesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public deleteCourse(employeeUuid: string, uuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeEditEducationCoursesSlice.actions.fetching())
                void await EmployeeAction.employeeEditApi.deleteCourse(employeeUuid, uuid)
                dispatch(employeeEditEducationCoursesSlice.actions.deleteCourse(uuid))
            } catch (e: any) {
                dispatch(employeeEditEducationCoursesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    //----------Courses-card----------//
    //----------Education----------//

    public clearEditUserData() {
        return (dispatch: AppDispatch) => {
            dispatch(employeeEditEducationCoursesSlice.actions.clearState())
            dispatch(employeeEditEducationEducationSlice.actions.clearState())
            dispatch(employeeEditWorkExperienceProjectsAndRolesSlice.actions.clearState())
            dispatch(employeeEditWorkExperiencePreviousJobSlice.actions.clearState())
            dispatch(employeeEditWorkInCompanyProjectsAndRolesSlice.actions.clearState())
            dispatch(employeeEditWorkInCompanyPreviousPositionSlice.actions.clearState())
            dispatch(employeeEditWorkInCompanyCurrentPositionSlice.actions.clearState())
            dispatch(employeeEditWorkInCompanyWorkExperienceSlice.actions.clearState())
            dispatch(employeeEditPersonalDataSlice.actions.clearState())
        }
    }

    // end edit page

    // view page

    public getEmployeeHistoryFilterEventTypes() {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeViewHistorySlice.actions.fetching())
                const data = await EmployeeAction.employeeFilterApi.getHistoryEventTypes()
                dispatch(employeeViewHistorySlice.actions.getHistoryEventTypes(data))
            } catch (e: any) {
                dispatch(employeeViewHistorySlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getEmployeeHistory(employeeUuid: string, params: { recently: string, eventTypeUuid: string }) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeViewHistorySlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getHistory(employeeUuid, params)
                dispatch(employeeViewHistorySlice.actions.getHistory(data))
            } catch (e: any) {
                dispatch(employeeViewHistorySlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public getCompetencies(employeeUuid: string, specializationUuid: string, gradeUuid: string) {
        return async (dispatch: AppDispatch) => {
            try {
                dispatch(employeeViewCompetenciesSlice.actions.fetching())
                const data = await EmployeeAction.employeeViewApi.getCompetencies(
                    employeeUuid,
                    specializationUuid,
                    gradeUuid,
                )
                dispatch(employeeViewCompetenciesSlice.actions.getCompetencies(data))
            } catch (e: any) {
                dispatch(employeeViewCompetenciesSlice.actions.fetchingError(e))
                throw e
            }
        }
    }

    public clearStateCompetence() {
        return (dispatch: AppDispatch) => {
            dispatch(employeeViewCompetenciesSlice.actions.clearState())
        }
    }

}
