import { AddCommentOutlined, CheckBoxOutlineBlankRounded, CheckBoxRounded, CommentOutlined } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { BadgeChangeLog } from 'components/badges/BadgeChangeLog/BadgeChangeLog'
import { DialogConfirmDelete } from 'components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import { DrawerComments } from 'components/drawers/DrawerComments/DrawerComments'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { LoadingComponent } from 'components/LoadingComponent/LoadingComponent'
import { SecondaryActions } from 'components/SecondaryActions/SecondaryActions'
import { formatHours } from 'helpers/dateAndTime/hoursToString'
import {
    HINT_PDP_ITEM_COMPETENCE_DELETE,
    HINT_SUCCESS_COMMENT_CREATE,
    HINT_SUCCESS_COMMENT_DELETE,
    HINT_SUCCESS_PDP_ITEM_DELETE,
} from 'helpers/enums/hints'
import { PATH_DEVELOPMENT, PATH_HISTORY } from 'helpers/enums/routePath'
import {
    TITLE_DIALOG_PDP_ITEM_DELETE,
    TITLE_NAMESPACE_COMMENT,
    TITLE_NAMESPACE_COMMENTS,
    TITLE_NAMESPACE_COMPETENCE_ACCUSATIVE,
    TITLE_NAMESPACE_DONE,
    TITLE_NAMESPACE_PLAN_ITEM,
    TITLE_NAMESPACE_STUDY_TIME,
    TITLE_NAMESPACE_USER_ACTIVITY,
} from 'helpers/enums/titles'
import { useDesktop } from 'helpers/hooks/useDesktop'
import { getPdpStatus } from 'helpers/pdp/getPdpStatus'
import {
    DialogEditPdpItemUnique,
} from 'pages/employees/EmployeePage/components/EmployeeTabs/components/EmployeeTabPdp/components/PdpItemViewPage/components/PdpItemViewUnique/dialogs/DialogEditPdpItemUnique/DialogEditPdpItemUnique'
import React, { useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { developmentPdpItemActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import { useDialogsState } from 'store/hooks/useDialogsState'
import { useLayoutFetch } from 'store/hooks/useFetch'
import { useNotify } from 'store/hooks/useNotify'
import { biggerBtnForMobile } from 'styles/styles'


export const DevelopmentPdpItemViewUnique = () => {
    const desktop = useDesktop()
    const { pdpUuid, itemUuid } = useParams()
    const navigate = useNavigate()
    const notify = useNotify()
    const dispatch = useAppDispatch()
    const { dialogCommentView, dialogEdit, dialogDelete, toggleDialog } = useDialogsState({
        dialogCommentView: false,
        dialogEdit: false,
        dialogDelete: false,
    })
    const { item } = useAppSelector(state => state.developmentPdpItemReducer.data)
    const { isFetching, error } = useLayoutFetch(developmentPdpItemActions.getPdpItem({
        pdpUuid: pdpUuid || '',
        itemUuid: itemUuid || '',
    }), {
        deps: [pdpUuid, itemUuid],
        permissions: { service: 'my_development', accessLevel: 'r' },
    })
    const pdpStatus = useMemo(() => getPdpStatus(item?.pdpStatus, false), [item?.pdpStatus?.uuid])
    const pdpStatusProgress = pdpStatus.status === 'inProgress'
    const pdpStatusReturned = pdpStatus.status === 'returned'
    const pdpStatusSent = pdpStatus.status === 'sent'
    const pdpStatusCreated = pdpStatus.status === 'default'
    const pdpStatusApproval = pdpStatus.status === 'approval'

    const disableEditComments = !(pdpStatusProgress || pdpStatusReturned || pdpStatusSent || pdpStatusCreated)

    const deleteComment = async (uuid: string) => {
        if (!pdpUuid || !itemUuid) return
        try {
            await dispatch(developmentPdpItemActions.deletePdpItemComment({
                uuid,
                pdpUuid,
                itemUuid,
            }))
            notify(HINT_SUCCESS_COMMENT_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const createComment = async (comment: IComment) => {
        if (!pdpUuid || !itemUuid) return
        try {
            await dispatch(developmentPdpItemActions.createPdpItemComment({
                ...comment,
                pdpUuid,
                itemUuid,
            }))
            notify(HINT_SUCCESS_COMMENT_CREATE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    const changeItemStatus = async () => {
        if (!itemUuid || !pdpUuid) return
        try {
            await dispatch(developmentPdpItemActions.passItemUnique({
                itemUuid,
                pdpUuid,
                isPassed: !item.isPassed,
            }))
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }
    const onDeleteConfirm = async () => {
        if (!pdpUuid) return
        try {
            await dispatch(developmentPdpItemActions.deletePdpItem({
                pdpUuid,
                itemUuid: item.uuid,
            }))
            await navigate(`${PATH_DEVELOPMENT}/pdp/${pdpUuid}`, { replace: true })
            notify(HINT_SUCCESS_PDP_ITEM_DELETE, 'success')
        } catch (e: any) {
            notify(e.userMessage, 'error')
        }
    }

    if (isFetching) return <LoadingComponent/>
    if (error) return <Navigate to={`${PATH_HISTORY}/pdp/${pdpUuid}`}/>
    if (!item) return <EmptyPage contentText={'Not found'}/>

    return (
        <Box>
            <DrawerComments
                open={dialogCommentView}
                disableEdit={disableEditComments}
                handleClose={() => toggleDialog('dialogCommentView', false)}
                entityTitle={TITLE_NAMESPACE_PLAN_ITEM}
                itemTitle={item.title}
                comments={item.comments}
                onDeleteComment={deleteComment}
                onCreateComment={createComment}
            />
            <DialogConfirmDelete
                open={dialogDelete}
                handleClose={() => toggleDialog('dialogDelete', false)}
                onSubmit={onDeleteConfirm}
                title={TITLE_DIALOG_PDP_ITEM_DELETE}
                elementTitle={item.title}
                helperText={HINT_PDP_ITEM_COMPETENCE_DELETE}
                elementType={TITLE_NAMESPACE_COMPETENCE_ACCUSATIVE}
            />
            {pdpUuid && item?.employee && <DialogEditPdpItemUnique
                itemPage
                open={dialogEdit}
                pdpUuid={pdpUuid}
                employee={item.employee}
                isDevelopment
                item={item}
                handleClose={() => toggleDialog('dialogEdit', false)}
            />}
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <Stack spacing={1}>
                    <Typography variant="h2">
                        {item?.title}
                    </Typography>
                    <Stack direction="row" alignItems='center' spacing={1}>
                        <Typography variant="body1">
                            {TITLE_NAMESPACE_USER_ACTIVITY}
                        </Typography>
                        {!!item?.changeLog &&
                            <BadgeChangeLog
                                changeLog={item?.changeLog}
                                evaluatedUuid={item?.employee?.uuid}
                                badgeDrawerTitle={item?.title}
                                badgeDrawerSubTitle={TITLE_NAMESPACE_USER_ACTIVITY}
                            />
                        }
                    </Stack>
                    <Typography variant="body1">
                        {TITLE_NAMESPACE_STUDY_TIME}: {formatHours(item?.studyTime)}
                    </Typography>
                </Stack>
                {pdpStatusCreated && <SecondaryActions
                    onDelete={() => toggleDialog('dialogDelete', true)}
                    onEdit={() => toggleDialog('dialogEdit', true)}
                />}
            </Box>
            <Stack
                direction={desktop ? 'row' : 'column'}
                alignItems="center"
                justifyContent={desktop ? 'flex-start' : 'center'}
                mb={6}
                gap={2}
                sx={{
                    '& .MuiButtonBase-root': {
                        mx: desktop ? 'initial' : 'auto',
                        width: desktop ? 'fit-content' : '100%',
                        maxWidth: desktop ? 'fit-content' : '600px',
                    },
                }}
            >
                {!pdpStatusCreated && !pdpStatusApproval &&
                    <Button
                        startIcon={item?.isPassed ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
                        variant={item?.isPassed ? 'contained' : 'outlined'}
                        disabled={pdpStatus.disableChangeItemStatus}
                        color="success"
                        onClick={changeItemStatus}
                        sx={desktop ? {} : biggerBtnForMobile}
                    >
                        {TITLE_NAMESPACE_DONE}
                    </Button>
                }
                <Button
                    startIcon={item.comments.length > 0
                        ? <CommentOutlined/>
                        : <AddCommentOutlined/>}
                    variant="outlined"
                    disabled={item.comments.length <= 0 && disableEditComments}
                    onClick={() => toggleDialog('dialogCommentView', true)}
                    sx={desktop ? {} : biggerBtnForMobile}
                >
                    {item.comments.length > 0 ? TITLE_NAMESPACE_COMMENTS : TITLE_NAMESPACE_COMMENT}
                </Button>
            </Stack>
            <Typography variant="body2">
                {item.description}
            </Typography>
        </Box>
    )
}

