import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAssessmentChangeStatusRequest } from 'api/typing/assessmentServiceTypes'
import { addDays, isPast, parse } from 'date-fns'
import {
    assessmentStatusAwaitingConfirmation,
    assessmentStatusCreated,
    getAssessmentStatus,
} from 'helpers/assessment/getAssessmentStatus'
import { containsText } from 'helpers/containsText'
import { cpaCriteriaCurrentUuid } from 'helpers/cpa/criteria/cpaCriteriaUuids'
import { isValidDateFromApi } from 'helpers/dateAndTime/isValidDateFromApi'
import {
    TITLE_BUTTON_ASSESSMENT_GO_TO,
    TITLE_BUTTON_ASSESSMENT_RESULTS,
    TITLE_BUTTON_ASSESSMENT_START,
    TITLE_BUTTON_PRELIMINARY_RESULTS,
    TITLE_BUTTON_PREVIEW_QUESTIONS,
    TITLE_NAMESPACE_CURRENT,
} from 'helpers/enums/titles'


interface IFilter {
    getSearch(): string;

    getStatuses(): string[];

    getTypes(): string[];

    getActiveFiltersCount(): number;
}

class Filter implements IFilter {
    constructor(
        private search: string,
        private statuses: string[],
        private types: string[],
    ) {}

    getSearch(): string {
        return this.search
    }

    getStatuses(): string[] {
        return this.statuses
    }

    getTypes(): string[] {
        return this.types
    }

    getActiveFiltersCount(): number {
        return [this.search, this.statuses, this.types].reduce((acc, el) => el.length > 0 ? acc + 1 : acc, 0)
    }
}

interface IFilterBuilder {
    setSearch(str: string): IFilterBuilder;
    setStatuses(uuids: string[]): IFilterBuilder;
    setTypes(uuids: string[]): IFilterBuilder;
    build(): IFilter;
}

class FilterBuilder implements IFilterBuilder {
    private search = ''
    private statusUuid: string[] = []
    private typeUuid: string[] = []
    private static instance: IFilterBuilder

    static getInstance() {
        if (!this.instance)
            this.instance = new FilterBuilder()
        return this.instance
    }

    build(): IFilter {
        return new Filter(this.search, this.statusUuid, this.typeUuid)
    }

    setSearch(str: string): IFilterBuilder {
        this.search = str
        return this
    }

    setStatuses(uuids: string[]): IFilterBuilder {
        this.statusUuid = uuids
        return this
    }

    setTypes(uuids: string[]): IFilterBuilder {
        this.typeUuid = uuids
        return this
    }

}

interface IAssessmentCollection {
    getList(): IAssessmentMeta[];
    getFilteredList(): IAssessmentMeta[];
}

class AssessmentCollection implements IAssessmentCollection {
    constructor(private list: IAssessmentMeta[], private filter: IFilter) {}

    getFilteredList(): IAssessmentMeta[] {
        return this.list.filter(el => this.compareString(el) && this.compareStatuses(el) && this.compareTypes(el))
    }

    private compareStatuses(element: IAssessmentMeta): boolean {
        const statuses = this.filter.getStatuses()
        if (!statuses?.length) return true

        return statuses.includes(element.statusUuid)
    }

    private compareTypes(element: IAssessmentMeta): boolean {
        const types = this.filter.getTypes()
        if (!types?.length) return true

        return types.includes(element.typeUuid)
    }

    private compareString(element: IAssessmentMeta): boolean {
        const search = this.filter.getSearch()
        if (!search?.length) return true

        return containsText(element.title, search)
    }

    getList(): IAssessmentMeta[] {
        return this.list
    }

}


interface IAssessmentMetaSetup {
    viewerUuid: string;
    createdStatus: boolean;
    estimationStatus: boolean;
    confirmationStatus: boolean;
    completeStatus: boolean;
    evaluatedIsInitiator: boolean;
    viewerIsEvaluated: boolean;
    isValidDate: boolean;
    isExpire: boolean;
    canSeeCriteria: boolean;
    viewButtonShow: boolean;
    viewButtonTitle: string;
    actionButtonShow: boolean;
    actionButtonTitle: string;
    canManageAssessment: boolean;
    displayedStatusUuid: string;
    positionTitle: string;
}

interface IAssessmentState {
    assessmentMeta: IAssessmentMeta | null;
    assessmentMetaSetup: IAssessmentMetaSetup | null;
    assessmentMetaList: {
        mainList: IAssessmentMeta[];
        scoresAndResults: {
            list: IAssessmentMeta[];
            filteredList: IAssessmentMeta[];
            filter: {
                search: string;
                statusUuid: string[];
                typeUuid: string[];
                activeFiltersCount: number,
            }
        };
        needToPass: {
            list: IAssessmentMeta[];
            filteredList: IAssessmentMeta[];
            // hasItems: boolean;
            filter: {
                search: string;
                typeUuid: string[];
                activeFiltersCount: number,
            }
        };
    };
}

const initialState: IState<IAssessmentState> = {
    data: {
        assessmentMeta: null,
        assessmentMetaSetup: null,
        assessmentMetaList: {
            mainList: [],
            scoresAndResults: {
                list: [],
                filteredList: [],
                filter: {
                    search: '',
                    statusUuid: [],
                    typeUuid: [],
                    activeFiltersCount: 0,
                },
            },
            needToPass: {
                list: [],
                filteredList: [],
                // hasItems: false,
                filter: {
                    search: '',
                    typeUuid: [],
                    activeFiltersCount: 0,
                },
            },
        },
    },
    isLoading: false,
    error: null,
}

const prepareMetaSetup = (assessment: IAssessmentMeta, viewerUuid: string): IAssessmentMetaSetup => {
    const assessmentStatus = getAssessmentStatus(assessment?.statusUuid)
    const createdStatus = assessmentStatus?.code === 'created'
    const estimationStatus = assessmentStatus?.code === 'estimation'
    const confirmationStatus = assessmentStatus?.code === 'confirmation'
    const completeStatus = assessmentStatus?.code === 'complete'
    const evaluatedIsInitiator = assessment?.initiator?.uuid === assessment?.evaluated?.uuid
    const viewerIsEvaluated = assessment?.evaluated?.uuid === viewerUuid
    // const hasCurrentReviewer = assessment.currentReviewer.length
    const currentReviewerIsViewer = assessment.currentReviewer.some(el => el.uuid === viewerUuid)
    const isValidDate = isValidDateFromApi(assessment?.endedAt)
    const isExpire = isValidDate && assessment && !completeStatus && isPast(addDays(parse(
        assessment?.endedAt,
        'dd.MM.yyyy',
        new Date(),
    ), 1))

    const canSeeCriteria = viewerIsEvaluated && evaluatedIsInitiator || completeStatus
    const viewButtonShow = evaluatedIsInitiator && viewerIsEvaluated && (createdStatus || estimationStatus)
        || completeStatus

    const viewButtonTitle = completeStatus
        ? TITLE_BUTTON_ASSESSMENT_RESULTS
        : estimationStatus || createdStatus ? TITLE_BUTTON_PREVIEW_QUESTIONS : TITLE_BUTTON_PRELIMINARY_RESULTS

    const actionButtonShow = createdStatus
        || estimationStatus && viewerIsEvaluated && !assessment?.isCompleteByRequestingUser
    const actionButtonTitle = createdStatus ? TITLE_BUTTON_ASSESSMENT_START : TITLE_BUTTON_ASSESSMENT_GO_TO

    const canManageAssessment = ((createdStatus || estimationStatus)
      && viewerIsEvaluated && evaluatedIsInitiator)
      || !completeStatus && currentReviewerIsViewer

    const displayedStatusUuid = estimationStatus && viewerIsEvaluated && assessment.isCompleteByRequestingUser
        ? assessmentStatusAwaitingConfirmation
        : assessment?.statusUuid

    const evaluatedSpecializationUuid = assessment?.evaluated?.currentPosition?.specializationUuid
    const evaluatedGradeUuid = assessment?.evaluated?.currentPosition?.gradeUuid

    const assessmentPointPositionTitle = evaluatedSpecializationUuid === assessment?.position?.specializationUuid
        && evaluatedGradeUuid === assessment?.position?.gradeUuid
        && `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        || assessment?.position?.title

    const positionTitle = assessment?.cpa?.criteria?.uuid === cpaCriteriaCurrentUuid
        ? `${assessment?.position?.title} (${TITLE_NAMESPACE_CURRENT})`
        : assessmentPointPositionTitle || assessment?.cpa?.criteria.title || ''

    return {
        createdStatus,
        estimationStatus,
        confirmationStatus,
        completeStatus,
        evaluatedIsInitiator,
        viewerIsEvaluated,
        isValidDate,
        isExpire,
        canSeeCriteria,
        viewButtonShow,
        viewButtonTitle,
        actionButtonShow,
        actionButtonTitle,
        canManageAssessment,
        displayedStatusUuid,
        positionTitle,
        viewerUuid,
    }
}

export const MyAssessmentListSlice = createSlice({
    name: 'MyAssessmentList',
    initialState,
    reducers: {
        fetching: (state) => {
            state.isLoading = true
        },
        fetchingError: (state, action: PayloadAction<IErrorResponse>) => {
            state.error = action.payload
            state.isLoading = false
        },
        fetchingSuccess: (state) => {
            state.error = null
            state.isLoading = false
        },
        clearState: () => initialState,

        setAssessmentMetaList: (state, action: PayloadAction<{ list: IAssessmentMeta[]; userUuid: string }>) => {
            const list = {
                scoresAndResults: [] as IAssessmentMeta[],
                needToPass: [] as IAssessmentMeta[],
            }
            action.payload.list.forEach(el => {
                const assessmentStatus = getAssessmentStatus(el.statusUuid)?.code
                const viewerIsEvaluated = action.payload.userUuid === el.evaluated.uuid
                const viewerIsInitiator = action.payload.userUuid === el.initiator.uuid
                if (el.isCompleteByRequestingUser && !viewerIsEvaluated) return
                const isNeedToPass = !el.isCompleteByRequestingUser
                    && (assessmentStatus === 'estimation'
                        || assessmentStatus === 'summarizing'
                        || assessmentStatus === 'noData'
                        || assessmentStatus === 'confirmation')

                if (isNeedToPass)
                    list.needToPass.push(el)

                if (viewerIsEvaluated && !(el.statusUuid === assessmentStatusCreated && !viewerIsInitiator))
                    list.scoresAndResults.push({
                        ...el,
                        statusUuid: assessmentStatus === 'estimation' && viewerIsEvaluated && el?.isCompleteByRequestingUser
                            ? assessmentStatusAwaitingConfirmation
                            : el?.statusUuid,
                    })
            })

            state.data.assessmentMetaList.scoresAndResults.list = list.scoresAndResults
            state.data.assessmentMetaList.mainList = action.payload.list
            state.data.assessmentMetaList.scoresAndResults.filteredList = list.scoresAndResults

            state.data.assessmentMetaList.needToPass.list = list.needToPass
            state.data.assessmentMetaList.needToPass.filteredList = list.needToPass

            state.data.assessmentMetaList.scoresAndResults.filter = {
                search: '',
                statusUuid: [],
                typeUuid: [],
                activeFiltersCount: 0,
            }
            state.data.assessmentMetaList.needToPass.filter = {
                search: '',
                typeUuid: [],
                activeFiltersCount: 0,
            }
        },

        setAssessmentMeta: (state, action: PayloadAction<{ assessment: IAssessmentMeta; userUuid: string }>) => {
            state.data.assessmentMeta = action.payload.assessment
            state.data.assessmentMetaSetup = prepareMetaSetup(action.payload.assessment, action.payload.userUuid)
        },
        deleteAssessmentMeta: (state) => {
            state.data.assessmentMeta = {} as IAssessmentMeta
        },
        startMyAssessment: (state, action: PayloadAction<IAssessmentChangeStatusRequest>) => {
            if (!state.data.assessmentMeta) return
            state.data.assessmentMeta.statusUuid = action.payload.statusUuid

            state.data.assessmentMetaSetup = prepareMetaSetup(
                state.data.assessmentMeta,
                state.data.assessmentMetaSetup?.viewerUuid || '',
            )
        },

        clearScoresAndResultsFilters: (state) => {
            const filter = FilterBuilder.getInstance().setSearch('').setStatuses([]).setTypes([]).build()
            state.data.assessmentMetaList.scoresAndResults.filter.search = filter.getSearch()
            state.data.assessmentMetaList.scoresAndResults.filter.statusUuid = filter.getStatuses()
            state.data.assessmentMetaList.scoresAndResults.filter.typeUuid = filter.getTypes()
            state.data.assessmentMetaList.scoresAndResults.filter.activeFiltersCount = filter.getActiveFiltersCount()

            const assessmentList = new AssessmentCollection(state.data.assessmentMetaList.scoresAndResults.list, filter)
            state.data.assessmentMetaList.scoresAndResults.filteredList = assessmentList.getFilteredList()
        },

        clearNeedToPassFilters: (state) => {
            const filter = FilterBuilder.getInstance().setSearch('').setTypes([]).build()
            state.data.assessmentMetaList.needToPass.filter.search = filter.getSearch()
            state.data.assessmentMetaList.needToPass.filter.typeUuid = filter.getTypes()
            state.data.assessmentMetaList.needToPass.filter.activeFiltersCount = filter.getActiveFiltersCount()

            const assessmentList = new AssessmentCollection(state.data.assessmentMetaList.needToPass.list, filter)
            state.data.assessmentMetaList.needToPass.filteredList = assessmentList.getFilteredList()
        },

        setSearchScoresAndResults: (state, action: PayloadAction<string>) => {
            const filter = FilterBuilder.getInstance().setSearch(action.payload).build()
            state.data.assessmentMetaList.scoresAndResults.filter.search = filter.getSearch()
            state.data.assessmentMetaList.scoresAndResults.filter.activeFiltersCount = filter.getActiveFiltersCount()

            const assessmentList = new AssessmentCollection(state.data.assessmentMetaList.scoresAndResults.list, filter)
            state.data.assessmentMetaList.scoresAndResults.filteredList = assessmentList.getFilteredList()
        },

        setStatusesScoresAndResults: (state, action: PayloadAction<string[]>) => {
            const filter = FilterBuilder.getInstance().setStatuses(action.payload).build()

            state.data.assessmentMetaList.scoresAndResults.filter.statusUuid = filter.getStatuses()
            state.data.assessmentMetaList.scoresAndResults.filter.activeFiltersCount = filter.getActiveFiltersCount()

            const assessmentList = new AssessmentCollection(state.data.assessmentMetaList.scoresAndResults.list, filter)
            state.data.assessmentMetaList.scoresAndResults.filteredList = assessmentList.getFilteredList()
        },

        setTypesScoresAndResults: (state, action: PayloadAction<string[]>) => {
            const filter = FilterBuilder.getInstance().setTypes(action.payload).build()

            state.data.assessmentMetaList.scoresAndResults.filter.typeUuid = filter.getTypes()
            state.data.assessmentMetaList.scoresAndResults.filter.activeFiltersCount = filter.getActiveFiltersCount()

            const assessmentList = new AssessmentCollection(state.data.assessmentMetaList.scoresAndResults.list, filter)
            state.data.assessmentMetaList.scoresAndResults.filteredList = assessmentList.getFilteredList()
        },

        setSearchNeedToPass: (state, action: PayloadAction<string>) => {
            const filter = FilterBuilder.getInstance().setSearch(action.payload).build()

            state.data.assessmentMetaList.needToPass.filter.search = filter.getSearch()
            state.data.assessmentMetaList.needToPass.filter.activeFiltersCount = filter.getActiveFiltersCount()

            const assessmentList = new AssessmentCollection(state.data.assessmentMetaList.needToPass.list, filter)
            state.data.assessmentMetaList.needToPass.filteredList = assessmentList.getFilteredList()
        },

        setTypesNeedToPass: (state, action: PayloadAction<string[]>) => {
            const filter = FilterBuilder.getInstance().setTypes(action.payload).build()

            state.data.assessmentMetaList.needToPass.filter.typeUuid = filter.getTypes()
            state.data.assessmentMetaList.needToPass.filter.activeFiltersCount = filter.getActiveFiltersCount()

            const assessmentList = new AssessmentCollection(state.data.assessmentMetaList.needToPass.list, filter)
            state.data.assessmentMetaList.needToPass.filteredList = assessmentList.getFilteredList()
        },
    },
})

export const myAssessmentListReducer = MyAssessmentListSlice.reducer
