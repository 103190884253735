import { Stack } from '@mui/material'
import {
    AssessmentResultAlertMain,
} from 'components/assessment/AssessmentResult/components/AssessmentResultAlertMain/AssessmentResultAlertMain'
import {
    AssessmentResultAccordance,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultAccordance/AssessmentResultAccordance'
import {
    AssessmentResultActions,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultActions/AssessmentResultActions'
import {
    AssessmentResultAlertPdp,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultAlertPdp/AssessmentResultAlertPdp'
import {
    AssessmentResultComments,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultComments/AssessmentResultComments'
import {
    AssessmentResultHeader,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultHeader/AssessmentResultHeader'
import {
    AssessmentResultSides,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultSides/AssessmentResultSides'
import {
    AssessmentResultSpider,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultSpider/AssessmentResultSpider'
import {
    AssessmentResultTree,
} from 'components/assessment/AssessmentResult/components/AssessmentResultDesktop/components/AssessmentResultTree/AssessmentResultTree'
import React, { FC } from 'react'
import { useAppSelector } from 'store/hooks/redux'


type AssessmentResultDesktopProps = {
    children?: React.ReactNode;
}

export const AssessmentResultDesktop: FC<AssessmentResultDesktopProps> = ({ children }) => {
    const graphicData = useAppSelector(state => state.assessmentResultReducer.data.accordanceGraphic)
    return (
        <Stack spacing={4}>
            <AssessmentResultHeader/>
            <AssessmentResultAlertMain/>
            <AssessmentResultSides/>
            {graphicData && <AssessmentResultAccordance/>}
            <AssessmentResultSpider/>
            <AssessmentResultComments/>
            <AssessmentResultAlertPdp/>
            <AssessmentResultTree/>
            {children
                ? children
                : <AssessmentResultActions/>}
        </Stack>
    )
}
