import { ChevronRightRounded, MoreHorizRounded } from '@mui/icons-material'
import { Box, ListSubheader, Tooltip } from '@mui/material'
import React, { type FC, type ReactNode, useEffect, useRef, useState } from 'react'


type Props = {
  tooltipText: string
  title: string;
  children: ReactNode
}

export const ListSubheaderWithTooltip: FC<Props> = ({
    children,
    tooltipText,
    title,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const listSubheaderRef = useRef<HTMLLIElement>(null)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry.isIntersecting) {
                    setIsOpen(false)
                }
            },
            { root: listSubheaderRef.current?.parentElement?.parentElement || listSubheaderRef.current?.parentElement },
        )

        if (listSubheaderRef.current) {
            observer.observe(listSubheaderRef.current)
        }

        return () => {
            if (listSubheaderRef.current) {
                observer.unobserve(listSubheaderRef.current)
            }
        }
    }, [])

    return (
        <Box>
            <ListSubheader
                ref={listSubheaderRef}
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                sx={{ position: 'sticky', top: '-8px' }}
            >
                <Tooltip
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    title={tooltipText}
                    placement="bottom-start"
                    disableFocusListener disableTouchListener
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                margin: '-4px !important',
                            },
                        },
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <MoreHorizRounded
                            color="disabled"
                            fontSize="small"
                        />
                        <ChevronRightRounded
                            color="disabled"
                            fontSize="small"
                            sx={{ mr: 0.5 }}
                        />
                        {title}
                    </Box>
                </Tooltip>
            </ListSubheader>
            {children}
        </Box>
    )
}

export default ListSubheaderWithTooltip
