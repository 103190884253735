import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { PageTitle } from 'components/PageTitle/PageTitle'
import { TITLE_BUTTON_ADD, TITLE_EMPTY_SPECIALIZATIONS, TITLE_PAGE_SPECIALIZATIONS } from 'helpers/enums/titles'
import { EmptyPage } from 'components/EmptyPage/EmptyPage'
import { AddRounded } from '@mui/icons-material'
import {
    AddNewSpecializationDialog,
} from 'pages/specialization/SpecializationList/components/dialogs/AddNewSpecializationDialog/AddNewSpecializationDialog'
import { specializationListActions } from 'store/actions'
import { useAppDispatch, useAppSelector } from 'store/hooks/redux'
import {
    SpecializationListContent,
} from 'pages/specialization/SpecializationList/components/SpecializationListContent/SpecializationListContent'
import { SendMetric } from 'utils/sendMetric'
import { HINT_WARNING_ADD_SPECIALIZATION_DOESNT_HAVE_DATA } from 'helpers/enums/hints'
import { useNotify } from 'store/hooks/useNotify'

export const SpecializationList = () => {
    const dispatch = useAppDispatch()
    const notify = useNotify()
    const { data: { list, createOneMeta }, isLoading } = useAppSelector(state => state.specializationListReducer)
    const [dialogCreate, setDialogCreate] = useState(false)
    const hasSpecializations = useMemo(() => list?.some(division => division.specializations.length > 0), [list])
    const openDialogCreate = () => {
        if (!createOneMeta?.specializations?.length || !createOneMeta?.divisions?.length) {
            notify(HINT_WARNING_ADD_SPECIALIZATION_DOESNT_HAVE_DATA, 'warning')
            return
        }
        SendMetric.getInstance().sendMetricClick('openModalAddNewSpecialization')
        setDialogCreate(true)
    }
    const closeDialogCreate = () => setDialogCreate(false)
    let isFetching = false

    useEffect(() => {
        const fetchData = async () => {
            isFetching = true
            await dispatch(specializationListActions.getList())
            await dispatch(specializationListActions.getMetaForCreateOne())
            isFetching = false
        }
        if (!isFetching) {
            fetchData().catch(e => e)
        }
    }, [])

    return (
        <Box display="flex" flexDirection="column" minHeight="100%">
            {dialogCreate && <AddNewSpecializationDialog
                handleClose={closeDialogCreate}
                open={dialogCreate}
            />}
            <Box>
                <PageTitle
                    title={TITLE_PAGE_SPECIALIZATIONS}
                    onCreate={openDialogCreate}
                    buttonTitle={TITLE_BUTTON_ADD}
                    service="specializations"
                    accessLevel="w"
                />
            </Box>
            {list?.length > 0 && hasSpecializations
                ? <SpecializationListContent list={list} />
                : !isLoading && <EmptyPage
                    contentText={TITLE_EMPTY_SPECIALIZATIONS}
                    startIcon={<AddRounded />}
                    onButtonClick={openDialogCreate}
                    service="specializations"
                    accessLevel="w"
                />}
        </Box>
    )
}

